import * as React from "react"

const EditIcon = (props) => (
  <svg width={19} height={19} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="m14.45 1.033-9.377 9.279a3.746 3.746 0 0 0-1.126 2.69v1.023c0 .201.081.395.226.538.144.143.34.223.543.223H5.75a3.855 3.855 0 0 0 2.719-1.114l9.376-9.28c.45-.446.702-1.05.702-1.68 0-.63-.252-1.234-.702-1.68A2.47 2.47 0 0 0 16.147.36a2.47 2.47 0 0 0-1.697.674Zm2.307 2.283-9.377 9.28a2.335 2.335 0 0 1-1.63.668h-.265v-.261a2.286 2.286 0 0 1 .676-1.615l9.376-9.28a.888.888 0 0 1 1.22 0 .85.85 0 0 1 0 1.208Z"
      fill="#972b34"
    />
    <path
      d="M17.79 7.158c-.203 0-.399.08-.543.223a.757.757 0 0 0-.225.539v3.821h-3.077a2.32 2.32 0 0 0-1.631.669 2.271 2.271 0 0 0-.676 1.614v3.045h-7.69a2.32 2.32 0 0 1-1.632-.669 2.271 2.271 0 0 1-.676-1.614V4.13c0-.606.243-1.186.676-1.615a2.32 2.32 0 0 1 1.631-.668h6.954c.204 0 .4-.08.544-.223a.757.757 0 0 0 0-1.077.773.773 0 0 0-.544-.223H3.947A3.87 3.87 0 0 0 1.23 1.44 3.79 3.79 0 0 0 .102 4.13v10.655a3.79 3.79 0 0 0 1.128 2.69 3.87 3.87 0 0 0 2.717 1.116h8.724a3.856 3.856 0 0 0 2.72-1.114l2.042-2.023a3.748 3.748 0 0 0 1.127-2.69V7.92a.757.757 0 0 0-.226-.539.773.773 0 0 0-.543-.223Zm-3.487 9.243c-.31.305-.7.516-1.127.608v-2.985c0-.201.081-.395.226-.538a.773.773 0 0 1 .543-.223h3.019a2.289 2.289 0 0 1-.615 1.114l-2.046 2.024Z"
      fill="#972b34"
    />
  </svg>
);

export default EditIcon
