import React, { useState, useEffect } from 'react';
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  TableContainer,
  TablePagination,
  Grid,
} from '@mui/material';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead } from '../sections/@dashboard/user';
import { SearchIcon, CloseIcon } from '../images/svg';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CircularProgress from '@mui/material/CircularProgress';
import moment from 'moment';
import './styles/merchant.css';
import { BASE_URL } from 'src/config/api';
import axios from 'axios';
import { IoIosAdd } from 'react-icons/io';
import DashboardCard from 'src/components/dashboardcard';
import { useAppContext } from './../context/AppContext';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));
// ----------------------------------------------------------------------
const TABLE_HEAD = [
  { id: 'merchant name', label: 'Merchant Name', alignRight: false },
  { id: 'Merchant Management', label: 'Merchant Management', alignRight: true },
];
// ----------------------------------------------------------------------

export default function Dashboard() {
  
  const [loading, setloading] = useState(false);
  const { state, dispatch } = useAppContext();
  const [adminUserData, setAdminUserData] = useState(JSON.parse(localStorage.getItem('adminUserData')));


  useEffect(() => {
    (async () => {
      // await setAdminUserData(JSON.parse( localStorage.getItem('adminUserData')));
      await fetchUsers()
      await fetch_merchant()
      await fetchCategories()
      await fetch_cards()
      await fetchdeals()
    })();
  }, []);




  const fetchUsers = async (search) => {
    setloading(true);
    axios({
      method: 'get',
      url: `${BASE_URL}/users`,
      withCredentails: false,
      params: {
        Authorization: adminUserData?.token,
      },
    })

      .then((res) => {
        setloading(false);
        dispatch({ type: 'ADD_USER', payload: res.data });
      })
      .catch((err) => {
        setloading(false);
      });
  };


  
  const fetch_merchant = async (search) => {
    setloading(true);
    axios({
      method: 'get',
      url: `${BASE_URL}/Merchant`,
      params: {
        Authorization: adminUserData?.token,
      },
    })
      .then((res) => {
        // setloading(false);
console.log("==========>",res.data)

        dispatch({ type: 'ADD_MERCHANT', payload: res.data });
      })
      .catch((err) => {
        setloading(false);
      });
  };


  const fetchCategories = async (search) => {
    setloading(true);
    axios({
      method: 'get',
      url: `${BASE_URL}/category`,
      withCredentails: false,
      params: {
        Authorization: adminUserData?.token,
      },
    })
      .then((res) => {
        setloading(false);
        
        
      
      
          dispatch({ type: 'ADD_CATEGORIES', payload: res.data });
          // dispatch({ type: 'ADD_INTERESTS', payload: totalTags });

        // setusersList([...res.data]);
      })
      .catch((err) => {
        setloading(false);
      });
  };

  const fetch_cards = async (search) => {
    setloading(true);
    axios({
      method: 'get',
      url: `${BASE_URL}/cards`,
      withCredentails: false,
      params: {
        Authorization: adminUserData?.token,
      },
    })
      .then((res) => {
        setloading(false);
        dispatch({ type: 'ADD_CARDS', payload: res.data});
      })
      .catch((err) => {
        setloading(false);
        console.log('====error====', err);
      });
  };
  console.log("==========>",state)

  const fetchdeals = async (search) => {
    setloading(true);
    axios({
      method: 'get',
      url: `${BASE_URL}/Deals`,
      withCredentails: false,
      params: {
        Authorization: adminUserData.token,
      },
    })
      .then((res) => {
        setloading(false);
        dispatch({ type: 'ADD_DEALS', payload: res.data});

      })
      .catch((err) => {
        console.log(res.response.data);

        setloading(false);
      });
  };

  return (
    <Page title="Dashboard">
      <Container>
        <Grid container>
          <Grid sm={12} xs={12} md={3} lg={6} xl={6}>
            <DashboardCard
              data={{
                title: 'Users',
                total: state.users.length,
                path: '/admin/users',
              }}
            />
          </Grid>

          <Grid sm={12} xs={12} md={3} lg={6} xl={6}>
            <DashboardCard
              data={{
                title: 'Merchant',
                total: state.merchants.length,
                path: '/admin/merchant',

              }}
            />
          </Grid>
         

          <Grid sm={12} xs={12} md={3} lg={3} xl={3}>
            <DashboardCard
              data={{
                title: 'Categories',
                total: state.categories.length,
                path: '/admin/category',
              }}
            />
          </Grid>
          <Grid sm={12} xs={12} md={3} lg={3} xl={3}>
            <DashboardCard
              data={{
                title: 'Interests',
                total: state.interests,
                path: '/admin/interest',

              }}
            />
          </Grid>
          <Grid sm={12} xs={12} md={3} lg={3} xl={3}>
            <DashboardCard
              data={{
                title: 'Cards',
                total: state.cards.length,
                path: '/admin/card',

              }}
            />
          </Grid>
          <Grid sm={12} xs={12} md={3} lg={3} xl={3}>
            <DashboardCard
              data={{
                title: 'Deals',
                total: state.deals.length,
                path: '/admin/deal',
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
