import React, { useState, useEffect } from 'react';
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  TableContainer,
  TablePagination,
  Grid,
} from '@mui/material';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead } from '../sections/@dashboard/user';
import { SearchIcon, CloseIcon, EyeIcon, DeleteIcon, EditIcon } from '../images/svg';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CircularProgress from '@mui/material/CircularProgress';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import moment from 'moment';
import './styles/merchant.css';
import { BASE_URL } from 'src/config/api';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
// import UserWallet from 'src/components/userWallet';
import { IoIosAdd } from 'react-icons/io';
import { BiCategory } from 'react-icons/bi';
import theme from '../theme/index';
import './styles/category.css';
import { TagsInput } from 'react-tag-input-component';
import { useAppContext } from './../context/AppContext';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));
// ----------------------------------------------------------------------
const TABLE_HEAD = [
  { id: 'Name', label: 'Name', alignRight: false },
  { id: 'Email', label: 'Email', alignRight: false },
  { id: 'Phone', label: 'Phone', alignRight: false },
  { id: 'Role', label: 'Role', alignRight: false },
  { id: 'Action', label: 'Action', alignRight: false },
];
// ----------------------------------------------------------------------

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
export default function Users() {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const { state, dispatch } = useAppContext();
  const [selected, setSelected] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [usersList, setusersList] = useState([]);
  const [viewDetail, setviewDetails] = useState(false);
  const [loading, setloading] = useState(false);
  const [reason, setreason] = useState('');
  const [searchText, setsearchText] = useState('');
  const [start_date, setstart_date] = useState(moment(new Date()).format('yyyy-MM-DD'));
  const [end_date, setend_date] = useState(moment(new Date()).format('yyyy-MM-DD'));
  const [adminUserData, setAdminUserData] = useState(JSON.parse(localStorage.getItem('adminUserData')));
  const [tags, settags] = useState(['papaya']);
  const [user_name, usern_name] = useState('');
  const [phone_num, set_phone_num] = useState('');
  const [user_email, setuser_email] = useState('');
  const [user_password, set_user_password] = useState('');
  const [role, setRole] = useState('manager');
  const [activeTab, setActiveTab] = useState('manager');
  const [value, setValue] = React.useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('success');
  const [snackOpen, setSnackOpen] = useState(false);

  const fetchUsers = async (search) => {
    setloading(true);
    axios({
      method: 'get',
      url: `${BASE_URL}/users`,
      withCredentails: false,
      // params: {
      //   Authorization: adminUserData?.token,
      // },
    })
      .then((res) => {
        setloading(false);
        // dispatch({ type: 'ADD_USER', payload: [...res.data] });
        dispatch({ type: 'ADD_USER', payload: res.data });

        setusersList([...res.data]);
      })
      .catch((err) => {
        setloading(false);
      });
  };

  useEffect(() => {
    (async () => {
      fetchUsers();
    })();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const updateUser = async () => {
    if (selected.name === '') {
      setErrorMessage('Please enter the User Name');
    } else {
      let axiosConfig = {
        name: selected.name,
      };
      await axios
        .patch(`${BASE_URL}/users/${selected.id}`, axiosConfig)
        .then((res) => {
          fetchUsers();
          console.log('===success==>', res.response);
          setSnackOpen(true);
          setMessage('user updated successfully');
          setMessageType('success');
          setOpen(false);
          setErrorMessage('');
        })
        .catch((err) => {
          console.log(err.response.data);
          if (err.response.request.status === 500) {
            setSnackOpen(true);
            setMessage(err.response.request.statusText);
            setMessageType('error');
          } else {
            setSnackOpen(true);
            setMessage(err.response.data.message);
            setMessageType('error');
          }
          // setErrorMessage(err.response.data);
        });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackOpen(false);
  };

  const delete_user = async (_id, userRole) => {
    let axiosConfig = {
      Authorization: adminUserData?.token,
    };

    var _confirm = confirm("Are you sure you want to delete or cancel to go back")
    // if (userRole === "manager") {
      if(_confirm){
    await axios
      .delete(`${BASE_URL}/users/${_id}`, axiosConfig)

      .then((res) => {
        fetchUsers();
        setSnackOpen(true);
        setMessage('user deleted successfully');
        setMessageType('success');
      })
      .catch((err) => {
        // console.log(err)
        if (err.response.request.status === 500) {
          setSnackOpen(true);
          setMessage(err.response.request.statusText);
          setMessageType('error');
        } else {
          setSnackOpen(true);
          setMessage(err.response.data.message);
          setMessageType('error');
        }
      });
    }
  };

  const create_user = async () => {
    if (user_name === '') {
      setErrorMessage('Please enter user name');
    } else if (user_email === '') {
      setErrorMessage('Please enter the user email');
    } else if (phone_num === '') {
      setErrorMessage('Please enter user phone number');
    } else if (user_password === '') {
      setErrorMessage('Please enter the user password');
    } else {
      let axiosConfig = {
        Authorization: adminUserData?.token,
      };
      await axios
        .post(
          `${BASE_URL}/users/register`,
          {
            name: user_name,
            phone: phone_num,
            role: role,
            email: user_email,
            password: user_password,
          },
          axiosConfig
        )
        .then((res) => {
          fetchUsers();
          setviewDetails(false);
          set_phone_num('');
          usern_name('');
          setErrorMessage('');
        })
        .catch((err) => {
          console.log(err.response.data);
          setErrorMessage(err.response.data.message);
        });
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const categoryhandlechange = (v, type) => {
    var _data = selected;
    console.log(_data.id);
    if (type === 'name') {
      _data.name = v;
      setSelected({ ..._data });
    } else if (type === 'email') {
      _data.email = v;
      setSelected({ ..._data });
    } else if (type === 'phone') {
      selected.phone = v;
      setSelected({ ..._data });
    }
    setErrorMessage('');
  };

  const handleChange = (event, newValue) => {
    if (newValue === 0) {
      setActiveTab('manager');
    } else {
      setActiveTab('appuser');
    }
    setValue(newValue);
    console.log(event);
  };

  console.log(start_date);

  const filteredUsers = usersList.filter((users) => {
    return (
      users.role?.toLowerCase() === activeTab.toLowerCase() &&
      users.name?.toLowerCase().includes(searchText.toLowerCase())
    );
  });
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredUsers.length) : 0;
  console.log('=====filteredUsers=====', filteredUsers);
  const isUserNotFound = filteredUsers.length === 0;
  return (
    <Page title="Users">
      {!viewDetail ? (
        <Container>
          <Grid container justifyContent={'space-between'}>
            <Grid sm={5} xs={5} md={3} lg={3} xl={3}>
              <div className="_login_row" style={{ marginBottom: 4 }}>
                <SearchIcon className="_auth_fields_icons" />
                <input
                  type={'text'}
                  placeholder="Search"
                  className="_auth_fields"
                  value={searchText}
                  onChange={(e) => setsearchText(e.target.value)}
                />
              </div>
            </Grid>
            <Grid sm={12} xs={12} md={3} lg={2} xl={2}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginBottom: 5 }}>
                {adminUserData.role === 'admin' ? (
                  <button
                    className="_btn"
                    style={{ width: '100%', alignSelf: 'flex-end' }}
                    // onClick={() => exportData()}
                    onClick={() => {
                      setviewDetails(true);
                    }}
                  >
                    <IoIosAdd style={{ color: 'white' }} size={25} /> Add Manager
                  </button>
                ) : null}
              </div>
            </Grid>

            {/* <Grid sm={12} xs={12} md={3} lg={2} xl={2}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginBottom: 5 }}>
                <button
                  className="_btn"
                  style={{ width: '100%', alignSelf: 'flex-end' }}
                  onClick={() => {
                    setviewDetails(true);
                  }}
                >
                  <IoIosAdd style={{ color: 'white' }} size={25} /> Add Category
                </button>
              </div>
            </Grid> */}
          </Grid>
          <br />
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="Managers" />
            <Tab label="Users" />
          </Tabs>

          <Card style={{ borderRadius: 0 }}>
            <Scrollbar>
              <TableContainer role="tabpanel">
                {loading ? (
                  <div className="_loading_section">
                    {' '}
                    <CircularProgress color="secondary" />
                  </div>
                ) : (
                  <Table>
                    <UserListHead headLabel={TABLE_HEAD} rowCount={usersList.length} />
                    <TableBody>
                      {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, key) => {
                        const { id, name, description, image } = row;
                        return (
                          <TableRow hover key={id} style={{ fontSize: 14, fontWeight: 400 }}>
                            <TableCell scope="row">
                              <Stack
                                direction="row"
                                alignItems="center"
                                spacing={2}
                                style={{ cursor: 'pointer' }}

                                // onClick={() => {
                                //   setviewWallet(true), setselectedUser(row);
                                // }}
                              >
                                {/* <BiCategory color={'#972b34'} size={20} /> */}
                                <div className="_profile_view">
                                  <img src={require('../images/useravatar.png')} className="_merchant_logo" />
                                </div>
                                <div>{row.name ? row.name : '--'}</div>
                              </Stack>
                            </TableCell>

                            <TableCell scope="row">
                              <Stack direction="row" alignItems="center" spacing={2} style={{ cursor: 'pointer' }}>
                                <div>{row.email ? row.email : '--'}</div>
                              </Stack>
                            </TableCell>
                            <TableCell>
                              <Stack direction="row" alignItems="center" spacing={2} style={{ cursor: 'pointer' }}>
                                <div>{row.phone ? row.phone : '--'}</div>
                              </Stack>
                            </TableCell>
                            <TableCell>
                              <Stack direction="row" alignItems="center" spacing={2} style={{ cursor: 'pointer' }}>
                                <div>{row.role ? row.role : '--'}</div>
                              </Stack>
                            </TableCell>

                            <TableCell align="right">
                              {adminUserData.role === 'admin' ? (
                                <div className="_action_row">
                                  <div
                                    onClick={() => {
                                      setSelected(row);
                                      setOpen(true);
                                      console.log('===>>>', row);
                                    }}
                                  >
                                    <EditIcon color="#972b34" />
                                  </div>
                                  <div onClick={() => delete_user(row.id, row.role)}>
                                    <DeleteIcon color="#972b34" />
                                  </div>
                                </div>
                              ) : null}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                    {isUserNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filterName} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                )}
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={filteredUsers.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
          {/* CHANGE STATUS MODAL */}

          <BootstrapDialog aria-labelledby="customized-dialog-title" open={open} maxWidth="sm" fullWidth={true}>
            <div className="_user_modal_header">
              <div>Edit Category</div>
              <span onClick={() => setOpen(false)}>
                <CloseIcon />
              </span>
            </div>

            <Grid container direction="row" justifyContent="center" alignItems="center" style={{ padding: 20 }}>
              <Grid sm={12}>
                <Grid container>
                  <Grid sm={12} xs={12} md={12} lg={12} xl={12}>
                    <div className="_verification_row">
                      <label>Name</label>
                      <input
                        className="_verification_fields"
                        value={selected?.name}
                        onChange={(e) => categoryhandlechange(e.target.value, 'name')}
                      />
                    </div>

                    <div className="_verification_row">
                      <label>Email</label>
                      <input
                        className="_verification_fields"
                        value={selected?.email}
                        onChange={(e) => categoryhandlechange(e.target.value, 'email')}
                        disabled
                      />
                    </div>
                  </Grid>
                  <Grid sm={12} xs={12} md={12} lg={12} xl={12}>
                    <div className="_verification_row">
                      <label>Phone</label>
                      <input
                        className="_verification_fields"
                        value={selected?.phone}
                        onChange={(e) => categoryhandlechange(e.target.value, 'phone')}
                        type="number"
                      />
                    </div>
                  </Grid>
                  <Grid sm={12} xs={12} md={12} lg={12} xl={12}>
                    <div className="_verification_row">
                      <p style={{ color: 'red', fontSize: 12 }}>{errorMessage}</p>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <DialogActions>
              <div className="_user_modal_footer">
                <button className="_btn outline_btn_modal" onClick={() => setOpen(false)}>
                  Cancel
                </button>
                <button className="_btn" onClick={() => updateUser()}>
                  Update
                </button>
              </div>
            </DialogActions>
          </BootstrapDialog>
        </Container>
      ) : (
        <Container>
          <div className="_user_details_title">
            {' '}
            <span>Users</span>/Add
          </div>
          <Grid container direction="row" justifyContent="center" alignItems="center">
            <Grid sm={12} xs={12} md={6} lg={6} xl={6}>
              <Grid container>
                <Grid sm={12} xs={12} md={12} lg={12} xl={12}>
                  <div className="_verification_row">
                    <label>User Name</label>
                    <input
                      className="_verification_fields"
                      value={user_name}
                      onChange={(e) => {
                        usern_name(e.target.value);
                        setErrorMessage('');
                      }}
                    />
                  </div>
                </Grid>

                <Grid sm={12} xs={12} md={12} lg={12} xl={12}>
                  <div className="_verification_row">
                    <label>Email</label>
                    <input
                      className="_verification_fields"
                      value={user_email}
                      onChange={(e) => {
                        setuser_email(e.target.value);
                        setErrorMessage('');
                      }}
                    />
                  </div>
                </Grid>
                <Grid sm={12} xs={12} md={12} lg={12} xl={12}>
                  <div className="_verification_row">
                    <label>Select Role</label>
                    <select value={role} className="_verification_fields" onChange={(e) => setRole(e.target.value)}>
                      {/* <option value={"admin"} >Admin</option> */}
                      <option value={'manager'}>Manager</option>
                    </select>
                  </div>
                </Grid>
                <Grid sm={12} xs={12} md={12} lg={12} xl={12}>
                  <div className="_verification_row">
                    <label>Phone num</label>
                    <input
                      className="_verification_fields"
                      value={phone_num}
                      onChange={(e) => {
                        set_phone_num(e.target.value);
                        setErrorMessage('');
                      }}
                    />
                  </div>
                </Grid>

                <Grid sm={12} xs={12} md={12} lg={12} xl={12}>
                  <div className="_verification_row">
                    <label>Password</label>
                    <input
                      className="_verification_fields"
                      value={user_password}
                      onChange={(e) => {
                        set_user_password(e.target.value);
                        setErrorMessage('');
                      }}
                    />
                  </div>
                </Grid>

                <Grid sm={12} xs={12} md={12} lg={12} xl={12}>
                  <div className="_verification_row">
                    <p style={{ color: 'red', fontSize: 12 }}>{errorMessage}</p>
                  </div>
                </Grid>

                <Grid sm={12} xs={12} md={12} lg={12} xl={12}>
                  <div className="_verification_row">
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                      }}
                      onClick={() => create_user()}
                    >
                      <button
                        style={{ width: 163 }}
                        className="_btn outline_btn_modal"
                        onClick={() => setviewDetails(false)}
                      >
                        Cancel
                      </button>
                      <button className="_btn" style={{ width: 163 }}>
                        Create
                      </button>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      )}
      <Snackbar open={snackOpen} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={messageType} sx={{ width: '100%' }} style={{ color: '#fff' }}>
          {message}
        </Alert>
      </Snackbar>
    </Page>
  );
}
