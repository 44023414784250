import * as React from "react"

const CloseIcon = (props) => (
  <svg
    width={22}
    height={22}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m17.372 6.43-4.518 4.518 4.582 4.582-1.803 1.803-4.582-4.582-4.498 4.497-1.633-1.633 4.497-4.498-4.603-4.603 1.803-1.803 4.603 4.603 4.519-4.518 1.633 1.633Z"
      fill="#EDEDED"
    />
  </svg>
)

export default CloseIcon
