import * as React from "react"

const HamBurger = (props) => (
  <svg
    width={25}
    height={19}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M.636 1.852c0-.83.67-1.5 1.5-1.5h18c.829 0 1.5.67 1.5 1.5s-.671 1.5-1.5 1.5h-18c-.83 0-1.5-.67-1.5-1.5Zm3 7.5c0-.83.67-1.5 1.5-1.5h18c.829 0 1.499.67 1.499 1.5s-.67 1.5-1.5 1.5H5.136c-.83 0-1.5-.67-1.5-1.5Zm18 7.5c0 .829-.671 1.5-1.5 1.5h-18c-.83 0-1.5-.671-1.5-1.5 0-.83.67-1.5 1.5-1.5h18c.829 0 1.5.67 1.5 1.5Z"
      fill="#5A5A5C"
    />
  </svg>
)

export default HamBurger
