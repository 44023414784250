import * as React from "react"

const DeleteIcon = (props) => (
  <svg width={18} height={22} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M17.091 4.344h-2.7a4.312 4.312 0 0 0-1.516-2.476 4.393 4.393 0 0 0-2.754-.973H8.378a4.393 4.393 0 0 0-2.755.973 4.312 4.312 0 0 0-1.515 2.476h-2.7a.876.876 0 0 0-.617.253.858.858 0 0 0 0 1.22.876.876 0 0 0 .616.252h.872v11.21a4.294 4.294 0 0 0 1.277 3.047 4.385 4.385 0 0 0 3.08 1.265h5.227a4.385 4.385 0 0 0 3.08-1.265 4.295 4.295 0 0 0 1.277-3.047V6.07h.872c.23 0 .452-.09.616-.253a.858.858 0 0 0 0-1.22.876.876 0 0 0-.616-.252ZM8.379 2.62h1.743c.54 0 1.067.166 1.509.475.44.309.775.745.956 1.25H5.913c.18-.505.515-.941.956-1.25a2.638 2.638 0 0 1 1.509-.475Zm6.1 14.66c0 .685-.276 1.343-.766 1.828a2.628 2.628 0 0 1-1.849.758H6.635a2.628 2.628 0 0 1-1.848-.758 2.574 2.574 0 0 1-.766-1.829V6.07h10.456v11.21Z"
      fill="#972b34"
    />
    <path
      d="M7.507 16.416c.23 0 .453-.09.616-.252a.858.858 0 0 0 .255-.61V10.38a.858.858 0 0 0-.255-.61.876.876 0 0 0-1.232 0 .858.858 0 0 0-.255.61v5.174c0 .229.091.448.255.61a.876.876 0 0 0 .616.252ZM10.992 16.416c.231 0 .453-.09.616-.252a.857.857 0 0 0 .256-.61V10.38a.857.857 0 0 0-.256-.61.876.876 0 0 0-1.232 0 .858.858 0 0 0-.255.61v5.174c0 .229.092.448.255.61a.876.876 0 0 0 .616.252Z"
      fill="#972b34"
    />
  </svg>
);

export default DeleteIcon
