import { useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink as RouterLink, matchPath, useLocation } from 'react-router-dom';
// material
import { alpha, useTheme, styled } from '@mui/material/styles';
import { Box, List, Collapse, ListItemText, ListItemIcon, ListItemButton } from '@mui/material';
import { FaIdeal } from 'react-icons/fa';
import { AiFillIdcard } from 'react-icons/ai';
//
import Iconify from './Iconify';
import { HiOutlineLogout } from 'react-icons/hi';
import { FaUsers ,FaLandmark} from 'react-icons/fa';
import { GiVerticalBanner } from 'react-icons/gi'
import {MdLocalOffer} from 'react-icons/md'
import {BiSolidLandmark} from 'react-icons/bi'
import { AppControlsIcon, EmailIcon, LoanIcon, LogoutIcon, UsersIcons, WithdrawlsIcon } from '../images/svg';
// ----------------------------------------------------------------------
import { BiCategory, BiHomeAlt } from 'react-icons/bi';
const ListItemStyle = styled((props) => <ListItemButton disableGutters {...props} />)(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: 'relative',
  textTransform: 'capitalize',
  color: theme.palette.text.secondary,
  // borderRadius: theme.shape.borderRadius,
  marginBottom: 5,
}));

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  color: 'inherit',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
  active: PropTypes.func,
};

function NavItem({ item, active }) {
  const theme = useTheme();

  const isActiveRoot = active(item.path);

  const { title, path, icon, info, children } = item;

  const [open, setOpen] = useState(isActiveRoot);

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const activeRootStyle = {
    color: 'primary.main',
    fontWeight: 'fontWeightMedium',
    bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
  };

  const activeSubStyle = {
    color: 'text.primary',
    fontWeight: 'fontWeightMedium',
  };

  if (children) {
    return (
      <>
        <ListItemStyle
          onClick={handleOpen}
          sx={{
            ...(isActiveRoot && activeRootStyle),
          }}
        >
          <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
          <ListItemText disableTypography primary={title} />
          {info && info}
          <Iconify
            icon={open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'}
            sx={{ width: 16, height: 16, ml: 1 }}
          />
        </ListItemStyle>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children.map((item) => {
              const { title, path } = item;
              const isActiveSub = active(path);

              return (
                <ListItemStyle
                  key={title}
                  component={RouterLink}
                  to={path}
                  sx={{
                    ...(isActiveSub && activeSubStyle),
                  }}
                >
                  <ListItemIconStyle>
                    <Box
                      component="span"
                      sx={{
                        width: 4,
                        height: 4,
                        display: 'flex',
                        borderRadius: '50%',
                        alignItems: 'center',
                        justifyContent: 'center',
                        bgcolor: 'text.disabled',
                        transition: (theme) => theme.transitions.create('transform'),
                        ...(isActiveSub && {
                          transform: 'scale(2)',
                          bgcolor: 'primary.main',
                        }),
                      }}
                    />
                  </ListItemIconStyle>
                  <ListItemText disableTypography primary={title} />
                </ListItemStyle>
              );
            })}
          </List>
        </Collapse>
      </>
    );
  }

  const { pathname } = useLocation();

  return (
    <ListItemStyle
      component={RouterLink}
      to={title === 'Logout' ? '/login' : path}
      sx={{
        ...(isActiveRoot && activeRootStyle),
      }}
      onClick={() => (title === 'Logout' ? localStorage.removeItem('adminUserData') : null)}
      style={{ borderLeft: `4px solid ${path === pathname ? '#972B34' : 'white'}` }}
    >
      <ListItemIconStyle>
        {path === '/admin/dashboard' && (
          <BiHomeAlt size={25} color={pathname === '/admin/dashboard' ? '#972B34' : '#637381'} />
        )}
        {path === '/admin/merchant' && <UsersIcons fill={pathname === '/admin/merchant' && '#972B34'} />}
        {path === '/admin/category' && <AppControlsIcon fill={pathname === '/admin/category' && '#972B34'} />}
        {path === '/admin/interest' && (
          <BiCategory size={25} color={pathname === '/admin/interest' ? '#972B34' : '#637381'} />
        )}

      {path === '/admin/landmark' && <FaLandmark size={25} color={pathname === '/admin/landmark' ? '#972B34' : '#637381'} />}

      {path === '/admin/deal' && <FaIdeal size={25} color={pathname === '/admin/deal' ? '#972B34' : '#637381'} />}
        {path === '/admin/promotion' && (
          <MdLocalOffer size={25} color={pathname === '/admin/promotion' ? '#972B34' : '#637381'} />
        )}
        {path === '/admin/banner' && (
          <GiVerticalBanner size={25} color={pathname === '/admin/banner' ? '#972B34' : '#637381'} />
        )}
        {path === '/admin/card' && (
          <AiFillIdcard size={24} color={pathname === '/admin/card' ? '#972B34' : '#637381'} />
        )}
        {path === '/admin/users' && <FaUsers size={24} color={pathname === '/admin/users' && '#972B34'} />}
        {path === '/admin/logout' && (
          <HiOutlineLogout size={24} color={pathname === '/admin/logout' ? '#972B34' : '#637381'} />
        )}
      </ListItemIconStyle>
      <ListItemText disableTypography primary={title} />
      {info && info}
    </ListItemStyle>
  );
}

NavSection.propTypes = {
  navConfig: PropTypes.array,
};

export default function NavSection({ navConfig, ...other }) {
  const { pathname } = useLocation();
  // const [adminUserData, setAdminUserData] = useState(JSON.parse(localStorage.getItem('adminUserData')));
  const match = (path) => (path ? !!matchPath({ path, end: false }, pathname) : false);

  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }} onClick={() => window.location.reload()}>

        {navConfig.map((item) => (
          <NavItem key={item.title} item={item} active={match} />
        ))}
      </List>
    </Box>
  );
}
