import React, { useState, useEffect, useCallback } from 'react';
import { TableRow, TableBody, TableCell, Container, TablePagination, Grid } from '@mui/material';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { SearchIcon, CloseIcon } from '../images/svg';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import CircularProgress from '@mui/material/CircularProgress';
import moment from 'moment';
import './styles/merchant.css';
import { BASE_URL, UPLOAD_URL } from 'src/config/api';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { IoIosAdd } from 'react-icons/io';
import './styles/promotion.css';
import { TagsInput } from 'react-tag-input-component';
import { HiOutlineLocationMarker } from 'react-icons/hi';
import { BiTime } from 'react-icons/bi';
import { AiOutlineEdit } from 'react-icons/ai';
import { RiDeleteBin6Line } from 'react-icons/ri';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { AiOutlineFileAdd } from 'react-icons/ai';
import TextField from '@mui/material/TextField';
import { Editor, EditorState, ContentState } from 'draft-js';
import 'draft-js/dist/Draft.css';
import { stateToHTML } from 'draft-js-export-html';
import { stateFromHTML } from 'draft-js-import-html';
import RichTextEditor from '../components/TextEditor';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));
// ----------------------------------------------------------------------
const TABLE_HEAD = [
  { id: 'Category', label: 'Category', alignRight: false },
  { id: 'Description', label: 'Description', alignRight: false },
  { id: 'Tags', label: 'Tags', alignRight: false },
  { id: 'Action', label: 'Action', alignRight: false },
];
// ----------------------------------------------------------------------
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
export default function Banner() {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [selected, setSelected] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [usersList, setusersList] = useState([]);
  const [viewDetail, setviewDetails] = useState(false);
  const [loading, setloading] = useState(false);
  const [searchText, setsearchText] = useState('');
  const [adminUserData, setAdminUserData] = useState(JSON.parse(localStorage.getItem('adminUserData')));
  const [merchant, setmerchant] = useState([]);
  const [banner_title, setbanner_title] = useState('');
  const [categoryList, setcategoryList] = useState([]);
  const [selected_cat, setselected_cat] = useState(null);
  const [selected_card, setselected_card] = useState(null);
  const [banner_desc, set_banner_desc] = useState('');
  const [banner_img, set_banner_img] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('success');
  const [snackOpen, setSnackOpen] = useState(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const fetch_banners = async (search) => {
    setloading(true);
    axios({
      method: 'get',
      url: `${BASE_URL}/banners`,

    })
      .then((res) => {
        console.log(res);
        setloading(false);
        setusersList([...res.data]);
      })
      .catch((err) => {
        setloading(false);
      });
  };

  useEffect(() => {
    (async () => {
      fetch_banners();
    })();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const udpate_banner = async () => {
    console.log(selected);
    if (selected.name === '') {
      setErrorMessage('Please  banner title');
    } else if (selected.description === '') {
      setErrorMessage('Please enter banner Description');
    } else if (selected.image === '') {
      setErrorMessage('Please add banner Image');
    } else {
      let axiosConfig = {
        title: selected?.name,
        description: selected?.description,
        image: selected.image,
      };
      console.log(selected);
      await axios
        .patch(`${BASE_URL}/banners/${selected.id}`, axiosConfig)
        .then((res) => {
          fetch_banners();
          setSnackOpen(true);
          setMessage('Banner updated successfully');
          setMessageType('success');
          setOpen(false);
        })
        .catch((err) => {
          console.log(err.response.data);
        });
    }
  };

  const upload_image = (_file, type) => {
    var formData = new FormData();
    formData.append('file', _file);
    formData.append('upload_preset', 'ml_default');
    formData.append('api_key', '767293436452984');
    axios({
      method: 'post',
      url: UPLOAD_URL,
      data: formData,
    })
      .then(function (response) {
        if (type === 'edit') {
          selected.image = response.data.url;
          setSelected({ ...selected });
          setErrorMessage('');
        } else {
          set_banner_img(response.data.url);
          setErrorMessage('');
        }
        console.log('////response/////', response.data.url);
      })
      .catch(function (error) {
        console.log('////error/////', error);
      });
  };

  const delete_banner = async (_id) => {
    var _confirm = confirm("Are you sure you want to delete or cancel to go back")
    if(_confirm){
    let axiosConfig = {
      Authorization: adminUserData?.token,
    };
    await axios
      .delete(`${BASE_URL}/banners/${_id}`, axiosConfig)

      .then((res) => {
        fetch_banners();
        setSnackOpen(true);
        setMessage('Banner deleted successfully');
        setMessageType('success');
      })
      .catch((err) => {
        console.log(err.data);
      });
    }
  };

  const save_banner = async () => {
    if (banner_title === '') {
      setErrorMessage('Please banner title');
    } else if (banner_desc === '') {
      setErrorMessage('Please enter banner description');
    } else if (banner_img === '') {
      setErrorMessage('Please add banner image');
    } else {
      let axiosConfig = {
        Authorization: adminUserData?.token,
      };
      await axios
        .post(
          `${BASE_URL}/banners`,
          {
            title: banner_title,
            description: banner_desc,
            image: banner_img,
          },
          axiosConfig
        )
        .then((res) => {
          setbanner_title('');
          set_banner_desc('');
          set_banner_img('');
          setErrorMessage('');
          setSnackOpen(true);
          setMessage('Banner added successfully');
          setMessageType('success');
          fetch_banners();
          setOpen(false);
          console.log('---------success--->', res);
        })
        .catch((err) => {
          setErrorMessage(err.response.data.message);
          console.log('=======))))))))==========>', err.response);
        });
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const promo_handlechange = (v, type) => {
    var _data = selected;
    // period
    // image
    console.log(_data.id);
    if (type === 'name') {
      _data.title = v;
    } else if (type === 'desc') {
      _data.description = v;
    } else if (type === 'image') {
      _data.image = v;
    }
    setSelected({ ..._data });
    setErrorMessage('');
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackOpen(false);
  };

  const tagshandleChange = (e) => {
    console.log('>>>>>>>>>>', e.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - usersList.length) : 0;

  // console.log(start_date);

  const filteredUsers = usersList.filter((users) => {
    return users.title?.toLowerCase().includes(searchText.toLowerCase());
  });

  const isUserNotFound = filteredUsers.length === 0;

  selected_cat?.tags?.map((element) => {
    return console.log(element);
  });

  return (
    <Page title="Banner">
      {!viewDetail ? (
        <Container>
          <Grid container justifyContent={'space-between'}>
            <Grid sm={5} xs={5} md={3} lg={3} xl={3}>
              <div className="_login_row" style={{ marginBottom: 4 }}>
                <SearchIcon className="_auth_fields_icons" />
                <input
                  type={'text'}
                  placeholder="Search"
                  className="_auth_fields"
                  value={searchText}
                  onChange={(e) => setsearchText(e.target.value)}
                />
              </div>
            </Grid>

            <Grid sm={12} xs={12} md={3} lg={2} xl={2}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginBottom: 5 }}>
                {adminUserData.role === 'admin' ? (
                  <button
                    className="_btn"
                    style={{ width: '100%', alignSelf: 'flex-end' }}
                    onClick={() => {
                      setviewDetails(true);
                    }}
                  >
                    <IoIosAdd style={{ color: 'white' }} size={25} /> Add Banner
                  </button>
                ) : null}
              </div>
            </Grid>
          </Grid>
          <br />
          <Scrollbar>
            <div>
              {loading ? (
                <div className="_loading_section">
                  {' '}
                  <CircularProgress color="secondary" />
                </div>
              ) : (
                <div>
                  {/* <UserListHead headLabel={TABLE_HEAD} rowCount={usersList.length} /> */}
                  <div container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, key) => {
                      const { id, name, description, image } = row;
                      return (
                        <div className="_list">
                          <div className="_promotion_image_section">
                            <img src={row.image} className="_promotion_image" />
                            {/* <div className="_tags _cat_tag">Code: {row.code}</div> */}
                          </div>

                          <div className="_middle_section">
                            <h4 className="_section_heading">{row.title}</h4>
                            {/* <p className="_banner_desc" dangerouslySetInnerHTML>{row.description}</p> */}
                            <div className="_banner_desc" dangerouslySetInnerHTML={{ __html: row.description }} />
                            <p className="_location_text">
                              <BiTime style={{ marginRight: 5 }} /> {row.createdAt}
                            </p>
                          </div>
                          {adminUserData.role === 'admin' ? (
                            <div className="_buttons_section">
                              <button
                                className="_btn _promotion_btn"
                                style={{ width: '100%', alignSelf: 'flex-end' }}
                                onClick={() => {
                                  setSelected(row), setOpen(true);
                                }}
                              >
                                <AiOutlineEdit style={{ color: 'white' }} /> Edit
                              </button>

                              <button
                                className="_btn _promotion_btn"
                                style={{ width: '100%', alignSelf: 'flex-end' }}
                                onClick={() => delete_banner(row.id)}
                              >
                                <RiDeleteBin6Line style={{ color: 'white' }} /> Delete
                              </button>
                            </div>
                          ) : null}
                        </div>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </div>
                  {isUserNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </div>
              )}
            </div>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={usersList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          {/* CHANGE STATUS MODAL */}

          <BootstrapDialog aria-labelledby="customized-dialog-title" open={open} maxWidth="sm" fullWidth={true}>
            <div className="_user_modal_header">
              <div>Edit Banner</div>
              <span onClick={() => setOpen(false)}>
                <CloseIcon />
              </span>
            </div>

            <Grid container direction="row" justifyContent="center" alignItems="center" style={{ padding: 20 }}>
              <Grid container>
                <Grid sm={12} xs={12} md={6} lg={6} xl={6}>
                  <div className="_verification_row">
                    <label>Title</label>
                    <input
                      className="_verification_fields"
                      value={selected?.title}
                      onChange={(e) => promo_handlechange(e.target.value, 'name')}
                    />
                  </div>
                </Grid>

                <Grid sm={12} xs={12} md={12} lg={12} xl={12}>
                  <div className="_verification_row">
                    <label>Description</label>
                    {/* <input
                      className="_verification_fields"
                      value={selected?.description}
                      style={{ height: 50 }}
                      onChange={(event, value) => promo_handlechange(value, 'desc')}
                    /> */}
                     <RichTextEditor
                      initialContent={selected?.description}
                      onChange={(content) => {
                        promo_handlechange(content, 'desc')
                      }}
                    />
                  </div>
                </Grid>

                <Grid sm={12} xs={12} md={12} lg={12} xl={12}>
                  <div className="_verification_row">
                    <label>Image</label>
                    {/* <label htmlFor="_update_promo"> */}
                    <label
                      htmlFor="_update_promo"
                      className="_verification_fields"
                      style={{ border: '1px solid', display: 'block', textAlign: 'center', height: 60 }}
                    >
                      <AiOutlineFileAdd size={16} color="#972B34" />
                      <div style={{ fontSize: 12 }}>Change banner image</div>
                    </label>
                    {selected?.image !== '' && (
                      <img src={selected?.image} style={{ height: 150, width: '100%', marginTop: 10 }} />
                    )}
                    {/* </label> */}
                    <input
                      className="_verification_fields"
                      type="file"
                      id="_update_promo"
                      style={{ height: 50, display: 'none' }}
                      onChange={(e) => upload_image(e.target.files[0], 'edit')}
                    />
                  </div>
                </Grid>
                <Grid sm={12} xs={12} md={12} lg={12} xl={12}>
                  <div className="_verification_row">
                    <p style={{ color: 'red', fontSize: 12 }}>{errorMessage}</p>
                  </div>
                </Grid>
              </Grid>
            </Grid>

            <DialogActions>
              <div className="_user_modal_footer">
                <button className="_btn outline_btn_modal" onClick={() => setOpen(false)}>
                  Cancel
                </button>
                <button className="_btn" onClick={() => udpate_banner()}>
                  Update
                </button>
              </div>
            </DialogActions>
          </BootstrapDialog>
        </Container>
      ) : (
        <Container>
          <div className="_user_details_title">
            {' '}
            <span>Banner</span>/Add Banner
          </div>
          <Grid container direction="row" justifyContent="center" alignItems="center">
            <Grid sm={12} xs={12} md={6} lg={6} xl={6}>
              <Grid container>
                <Grid sm={12} xs={12} md={12} lg={12} xl={12}>
                  <div className="_verification_row">
                    <label>Name</label>
                    <input
                      className="_verification_fields"
                      value={banner_title}
                      onChange={(e) => {
                        setbanner_title(e.target.value);
                        setErrorMessage('');
                      }}
                    />
                  </div>
                </Grid>

                <Grid sm={12} xs={12} md={12} lg={12} xl={12}>
                  <div className="_verification_row">
                    <label>Description</label>
                    <RichTextEditor
                      initialContent={banner_desc}
                      onChange={(content) => {
                        set_banner_desc(content);
                        setErrorMessage('');
                      }}
                    />
                  </div>
                </Grid>

                <Grid sm={12} xs={12} md={12} lg={12} xl={12}>
                  <div className="_verification_row">
                    <label>Image</label>
                    {/* <label htmlFor="_update_promo"> */}
                    <label
                      htmlFor="_update_promo"
                      className="_verification_fields"
                      style={{ border: '1px solid', display: 'block', textAlign: 'center', height: 60 }}
                    >
                      <AiOutlineFileAdd size={16} color="#972B34" />
                      <div style={{ fontSize: 12 }}>upload banner image</div>
                    </label>
                    {banner_img !== '' && (
                      <img src={banner_img} style={{ height: 150, width: '100%', marginTop: 10 }} />
                    )}
                    {/* </label> */}
                    <input
                      className="_verification_fields"
                      type="file"
                      id="_update_promo"
                      style={{ height: 50, display: 'none' }}
                      onChange={(e) => upload_image(e.target.files[0], 'save')}
                    />
                  </div>
                </Grid>

                <Grid sm={12} xs={12} md={12} lg={12} xl={12}>
                  <div className="_verification_row">
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        cursor: 'pointer',
                      }}
                      onClick={() => save_banner()}
                    >
                      <button
                        style={{ width: 163 }}
                        className="_btn outline_btn_modal"
                        onClick={() => setviewDetails(false)}
                      >
                        Cancel
                      </button>
                      <button className="_btn" style={{ width: 163 }}>
                        Save
                      </button>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      )}
      <Snackbar open={snackOpen} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={messageType} sx={{ width: '100%' }} style={{ color: '#fff' }}>
          {message}
        </Alert>
      </Snackbar>
    </Page>
  );
}
