import React, { useState, useEffect } from 'react';
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  TableContainer,
  TablePagination,
  Grid,
} from '@mui/material';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead } from '../sections/@dashboard/user';
import { SearchIcon, CloseIcon, EyeIcon, DeleteIcon, EditIcon } from '../images/svg';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CircularProgress from '@mui/material/CircularProgress';
import moment from 'moment';
import './styles/merchant.css';
import { BASE_URL } from 'src/config/api';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
// import UserWallet from 'src/components/userWallet';
import { IoIosAdd } from 'react-icons/io';
import { BiCategory } from 'react-icons/bi';
import theme from '../theme/index';
import './styles/category.css';
import { TagsInput } from 'react-tag-input-component';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));
// ----------------------------------------------------------------------
const TABLE_HEAD = [
  { id: 'Category', label: 'Category', alignRight: false },
  { id: 'Interest', label: 'Interest', alignRight: false },
  { id: 'Action', label: 'Action', alignRight: false },
];
// ----------------------------------------------------------------------

export default function Interest() {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [selected, setSelected] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [usersList, setusersList] = useState([]);
  const [viewDetail, setviewDetails] = useState(false);
  const [loading, setloading] = useState(false);
  const [reason, setreason] = useState('');
  const [searchText, setsearchText] = useState('');
  const [start_date, setstart_date] = useState(moment(new Date()).format('yyyy-MM-DD'));
  const [end_date, setend_date] = useState(moment(new Date()).format('yyyy-MM-DD'));
  const [adminUserData, setAdminUserData] = useState(JSON.parse(localStorage.getItem('adminUserData')));
  const [tags, settags] = useState(['papaya']);
  const [cat_title, setcat_title] = useState('');
  const [cat_desc, setcat_desc] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('success');
  const [snackOpen, setSnackOpen] = useState(false);

  const fetchUsers = async (search) => {
    setloading(true);
    axios({
      method: 'get',
      url: `${BASE_URL}/category`,
      withCredentails: false,
      params: {
        Authorization: adminUserData?.token,
      },
    })
      .then((res) => {
        setloading(false);
        setusersList([...res.data]);
      })
      .catch((err) => {
        setloading(false);
      });
  };

  useEffect(() => {
    (async () => {
      fetchUsers();
    })();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackOpen(false);
  };

  const updateCategory = async () => {
    if (selected.tags.length === 0) {
      setErrorMessage('Please Select Tags');
    } else {
      console.log('=============', selected);
      let axiosConfig = {
        name: selected.name,
        description: selected.description,
        image: 'sit magna',
        tags: selected.tags,
      };
      await axios
        .patch(`${BASE_URL}/category/${selected.id}`, axiosConfig)
        .then((res) => {
          fetchUsers();
          setOpen(false);
          setErrorMessage('');
          setSnackOpen(true);
          setMessage('Interest updated successfully');
          setMessageType('success');
        })
        .catch((err) => {
          // console.log(err.response.data);
          setErrorMessage(err.response.data.message);
        });
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const categoryhandlechange = (v, type) => {
    var _data = selected;
    console.log(_data.id);
    if (type === 'name') {
      _data.name = v;
      setSelected({ ..._data });
    } else if (type === 'desc') {
      _data.description = v;
      setSelected({ ..._data });
    } else if (type === 'tags') {
      selected.tags = v;
      setSelected(_data);
    }
    setErrorMessage('');
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - usersList.length) : 0;

  console.log(start_date);

  const filteredUsers = usersList.filter((users) => {
    return users.name?.toLowerCase().includes(searchText.toLowerCase());
  });
  const isUserNotFound = filteredUsers.length === 0;
  return (
    <Page title="Users">
      <Container>
        <Grid container justifyContent={'space-between'}>
          <Grid sm={5} xs={5} md={3} lg={3} xl={3}>
            <div className="_login_row" style={{ marginBottom: 4 }}>
              <SearchIcon className="_auth_fields_icons" />
              <input
                type={'text'}
                placeholder="Search"
                className="_auth_fields"
                value={searchText}
                onChange={(e) => setsearchText(e.target.value)}
              />
            </div>
          </Grid>
        </Grid>
        <br />
        <Card style={{ borderRadius: 0 }}>
          <Scrollbar>
            <TableContainer>
              {loading ? (
                <div className="_loading_section">
                  {' '}
                  <CircularProgress color="secondary" />
                </div>
              ) : (
                <Table>
                  <UserListHead headLabel={TABLE_HEAD} rowCount={usersList.length} />
                  <TableBody>
                    {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, key) => {
                      const { id, name, description, image } = row;
                      return (
                        <TableRow hover key={id} style={{ fontSize: 14, fontWeight: 400 }}>
                          <TableCell scope="row">
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                              style={{ cursor: 'pointer' }}

                            // onClick={() => {
                            //   setviewWallet(true), setselectedUser(row);
                            // }}
                            >
                              <BiCategory color={'#972b34'} size={20} />

                              <div>{row.name ? row.name : '--'}</div>
                            </Stack>
                          </TableCell>

                          <TableCell align="left">
                            {row.tags.map((val, i) => {
                              return (
                                <div key={i} className="_tags">
                                  {val}
                                </div>
                              );
                            })}
                          </TableCell>

                          <TableCell align="right">
                            <div className="_action_row">
                              {
                                adminUserData.role === "admin" ?
                                  <div
                                    onClick={() => {
                                      setSelected(row), setOpen(true);
                                    }}
                                  >
                                    <button
                                      className="_btn"
                                      style={{ width: 150, alignSelf: 'flex-end', fontSize: 12 }}
                                    >
                                      <IoIosAdd style={{ color: 'white' }} size={25} /> Add
                                    </button>
                                  </div> : null
                              }
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                  {isUserNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              )}
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={usersList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
        {/* CHANGE STATUS MODAL */}

        <BootstrapDialog aria-labelledby="customized-dialog-title" open={open} maxWidth="sm" fullWidth={true}>
          <div className="_user_modal_header">
            <div>Add Interest</div>
            <span onClick={() => setOpen(false)}>
              <CloseIcon />
            </span>
          </div>

          <Grid container direction="row" justifyContent="center" alignItems="center" style={{ padding: 20 }}>
            <Grid sm={12}>
              <Grid container>
                <Grid sm={12} xs={12} md={12} lg={12} xl={12}>
                  <div className="_verification_row">
                    <label style={{ marginBottom: 10, display: 'inline-block' }}>Interest</label>

                    <TagsInput
                      className="rti--container"
                      value={selected?.tags}
                      // onChange={settags}
                      onChange={(e) => {
                        categoryhandlechange(e, 'tags');
                      }}
                      name="tags"
                      placeHolder="Enter ..."
                    />
                  </div>
                </Grid>
                <Grid sm={12} xs={12} md={12} lg={12} xl={12}>
                  <div className="_verification_row">
                    <p style={{ color: 'red', fontSize: 12 }}>{errorMessage}</p>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <DialogActions>
            <div className="_user_modal_footer">
              <button className="_btn outline_btn_modal" onClick={() => setOpen(false)}>
                Cancel
              </button>
              <button className="_btn" onClick={() => updateCategory()}>
                Save
              </button>
            </div>
          </DialogActions>
        </BootstrapDialog>
      </Container>
      <Snackbar open={snackOpen} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={messageType} sx={{ width: '100%' }} style={{ color: '#fff' }}>
          {message}
        </Alert>
      </Snackbar>
    </Page>
  );
}
