// ----------------------------------------------------------------------


let localData = localStorage.getItem("currentuser")
if(localData !== null){
  localData = JSON.parse(localData)
}


const account = {
  displayName: localData?.username ? localData.username:"admin",
  email: 'pepecornejo@gmail.com',
  photoURL: 'https://fiverr-res.cloudinary.com/t_profile_original,q_auto,f_auto/attachments/profile/photo/3436226f1fe9e33704f39e578ea8b959-1197339681647208766589/JPEG_20220313_165922_6550601309184453670.jpg',
};







export default account;
