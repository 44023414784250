// AppContext.js
import { createContext, useContext, useReducer } from 'react';

// Initial state
const initialState = {
  users: [],
  posts: [],
  merchants:[],
  categories:[],
  interests:0,
  cards:[],
  deals:[]
};

// Create context
const AppContext = createContext();

// Create a reducer function to handle state changes
const appReducer = (state, action) => {
  switch (action.type) {
    case 'ADD_USER':
      return {
        ...state,
        users: [...action.payload],
      };
    case 'ADD_POST':
      return {
        ...state,
        posts: [...state.posts, action.payload],
      };

      case 'ADD_MERCHANT':
      return {
        ...state,
        merchants: [...action.payload],
      };

      case 'ADD_CATEGORIES':

      var totalTags = 0;
        [...action.payload].forEach(function(obj) {
            totalTags += obj.tags.length;
        })
      return {
        ...state,
        categories: [...action.payload],
        interests:totalTags
      };

      case 'ADD_CARDS':
      return {
        ...state,
        cards: [...action.payload],
      };

      case 'ADD_DEALS':
        return {
          ...state,
          deals: [...action.payload],
        };
    // Add more cases as needed for other actions      
    default:
      return state;
  }
};

// Create a custom provider component
const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(appReducer, initialState);

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  );
};

// Create a custom hook to access the context
const useAppContext = () => {
  return useContext(AppContext);
};

export { AppProvider, useAppContext };
