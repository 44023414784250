import React, { useState, useEffect } from 'react';
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  TableContainer,
  TablePagination,
  Grid,
} from '@mui/material';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead } from '../sections/@dashboard/user';
import { SearchIcon, CloseIcon, EyeIcon, DeleteIcon, EditIcon } from '../images/svg';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import CircularProgress from '@mui/material/CircularProgress';
import moment from 'moment';
import './styles/merchant.css';
import { BASE_URL, UPLOAD_URL } from 'src/config/api';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
// import UserWallet from 'src/components/userWallet';
import { IoIosAdd } from 'react-icons/io';
import { BiCategory } from 'react-icons/bi';
import theme from '../theme/index';
import './styles/category.css';
import { TagsInput } from 'react-tag-input-component';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import ImageUpload from 'src/components/FileUpload';
import { AiOutlineFileAdd } from 'react-icons/ai';
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));
// ----------------------------------------------------------------------
const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  // { id: 'merchant', label: 'Merchant', alignRight: false },
  { id: 'description', label: 'Description', alignRight: false },
  { id: 'price', label: 'Price', alignRight: false },
  // { id: 'programme', label: 'Programme', alignRight: false },
  { id: 'Action', label: 'Action', alignRight: false },
];
// ----------------------------------------------------------------------

export default function PromotionCard() {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [selected, setSelected] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [usersList, setusersList] = useState([]);
  const [merchant_list, set_merchant_list] = useState([]);
  const [viewDetail, setviewDetails] = useState(false);
  const [loading, setloading] = useState(false);
  const [reason, setreason] = useState('');
  const [searchText, setsearchText] = useState('');
  const [start_date, setstart_date] = useState(moment(new Date()).format('yyyy-MM-DD'));
  const [end_date, setend_date] = useState(moment(new Date()).format('yyyy-MM-DD'));
  const [adminUserData, setAdminUserData] = useState(JSON.parse(localStorage.getItem('adminUserData')));
  const [tags, settags] = useState(['papaya']);
  const [card_title, setcard_title] = useState('');
  const [price, set_price] = useState('');
  const [program, set_program] = useState('');
  const [merchant, set_merchant] = useState(0);

  const [card_desc, setcard_desc] = useState('');
  const [card_price, setcard_price] = useState('');

  const [card_img, set_card_img] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('success');
  const [snackOpen, setSnackOpen] = useState(false);

  const fetch_cards = async (search) => {
    setloading(true);
    axios({
      method: 'get',
      url: `${BASE_URL}/cards`,
      withCredentails: false,
      params: {
        Authorization: adminUserData?.token,
      },
    })
      .then((res) => {
        setloading(false);
        setusersList([...res.data]);
      })
      .catch((err) => {
        setloading(false);
        console.log('====error====', err);
      });
  };

  const fetch_merchant = async (search) => {
    setloading(true);
    axios({
      method: 'get',
      url: `${BASE_URL}/Merchant`,
      // params: {
      //   Authorization: adminUserData?.token,
      // },
    })
      .then((res) => {
        setloading(false);
        set_merchant_list(res.data);
        console.log('==========>>users=====>>>', res.data);
      })
      .catch((err) => {
        setloading(false);
      });
  };

  useEffect(() => {
    (async () => {
      fetch_cards();
      fetch_merchant();
    })();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const update_card = async () => {
    // if (selected.name === '') {
    //   setErrorMessage('Please enter the Category Name');
    // } else if (selected.description === '') {
    //   setErrorMessage('Please enter the Category Description');
    // } else if (selected.tags.length === 0) {
    //   setErrorMessage('Please Select the Category Tags');
    // } else if (selected.image === '') {
    //   setErrorMessage('Please Select the Category Image');
    // } else {
    //   // console.log('=============', selected);
    //   let axiosConfig = {
    //     name: selected.name,
    //     description: selected.description,
    //     image: selected.image,
    //     tags: selected.tags,
    //   };
    //   await axios
    //     .patch(`${BASE_URL}/category/${selected.id}`, axiosConfig)
    //     .then((res) => {
    //       fetch_cards();
    //       console.log('===success==>', res.response);
    //       setOpen(false);
    //       setErrorMessage('');
    //       setSnackOpen(true);
    //       setMessage('card updated successfully');
    //       setMessageType('success');
    //     })
    //     .catch((err) => {
    //       console.log(err.response.data);
    //       setErrorMessage(err.response.data);
    //     });
    // }
    if (selected.name === '') {
      setErrorMessage('Please enter card name');
    } else if (selected.description === '') {
      setErrorMessage('Please enter card  description');
    }
    //  else if (selected.price === '') {
    //   setErrorMessage('Please enter card price');
    // }
    // else if (selected.program === '') {
    //   setErrorMessage('Please enter program');
    // }
    else if (selected.image === '') {
      setErrorMessage('Please Select the card image');
    } else {
      let axiosConfig = {
        Authorization: adminUserData?.token,
      };
      await axios

        .patch(
          `${BASE_URL}/cards/${selected.id}`,
          {
            name: selected?.name,
            description: selected?.description,
            image: selected?.image,
            price: selected?.price,
            program: selected?.program,
            merchant: selected?.mer,
          },
          axiosConfig
        )
        .then((res) => {
          fetch_cards();
          setviewDetails(false);
          setcard_desc('');
          setcard_title('');
          setcard_price('');
          set_program('');
          set_card_img('');
          setErrorMessage('');
          setSnackOpen(true);
          setMessage('card updated successfully');
          setMessageType('success');
          setOpen(false);
        })
        .catch((err) => {
          console.log(err.response);
          setErrorMessage(err.data);
        });
    }
  };

  const delete_card = async (_id) => {
    let axiosConfig = {
      // Authorization: adminUserData?.token,
    };
    // axios.delete(`${BASE_URL}/cards/${_id}`)
    //   .then((res) => {
    //     fetch_cards();
    //     setSnackOpen(true);
    //     setMessage('Card deleted successfully');
    //     setMessageType('success');
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
    var _confirm = confirm("Are you sure you want to delete or cancel to go back")
if(_confirm){
    await axios
      .delete(`${BASE_URL}/cards/${_id}`, axiosConfig)

      .then((res) => {
        fetch_cards();
        setSnackOpen(true);
        setMessage('Card deleted successfully');
        setMessageType('success');
      })
      .catch((err) => {
        console.log(err);
      });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackOpen(false);
  };

  const save_card = async () => {
    console.log(merchant_list[merchant]);
    if (card_title === '') {
      setErrorMessage('Please enter card name');
    } else if (card_desc === '') {
      setErrorMessage('Please enter card  description');
    }
    //  else if (price === '') {
    //   setErrorMessage('Please enter card price');
    // }
    //  else if (program === '') {
    //   setErrorMessage('Please enter program');
    // }
    // else if (merchant === '') {
    //   setErrorMessage('Please Select the merchant');
    // }
    else if (card_img === '') {
      setErrorMessage('Please Select the card image');
    } else {
      let axiosConfig = {
        Authorization: adminUserData?.token,
      };
      console.log(merchant_list);
      console.log(merchant);
      await axios
        .post(
          `${BASE_URL}/cards`,
          {
            name: card_title,
            description: card_desc,
            image: card_img,
            price: card_price,
            // program: program,
            // merchant: { name: merchant_list[merchant].name, id: merchant_list[merchant].id },
          },
          axiosConfig
        )
        .then((res) => {
          fetch_cards();
          setviewDetails(false);
          setcard_desc('');
          setcard_title('');
          set_price('');
          set_program('');
          set_card_img('');
          setErrorMessage('');
          setSnackOpen(true);
          setMessage('Category saved successfully');
          setMessageType('success');
        })
        .catch((err) => {
          console.log(err.response);
          setErrorMessage(err.data);
        });
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const categoryhandlechange = (v, type) => {
    var _data = selected;
    console.log(_data.id);
    if (type === 'name') {
      _data.name = v;
      setSelected({ ..._data });
    } else if (type === 'desc') {
      _data.description = v;
      setSelected({ ..._data });
    } else if (type === 'tags') {
      selected.tags = v;
      setSelected(_data);
    }
    // if()
    setErrorMessage('');
  };

  // UPLOAD_URL
  const upload_image = (_file, type) => {
    console.log('-------_file-------->', _file);
    var formData = new FormData();
    formData.append('file', _file);
    formData.append('upload_preset', 'ml_default');
    formData.append('api_key', '767293436452984');

    axios({
      method: 'post',
      url: UPLOAD_URL,
      data: formData,
    })
      .then(function (response) {
        if (type === 'edit') {
          selected.image = response.data.url;
          setSelected({ ...selected });
        } else {
          set_card_img(response.data.url);
          setErrorMessage('');
        }
        console.log('////response/////', response.data.url);
      })
      .catch(function (error) {
        console.log('////error/////', error);
      });
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - usersList.length) : 0;

  console.log(start_date);

  const filteredUsers = usersList.filter((users) => {
    return users.name?.toLowerCase().includes(searchText.toLowerCase());
  });
  const isUserNotFound = filteredUsers.length === 0;
  return (
    <Page title="Users">
      {!viewDetail ? (
        <Container>
          <Grid container justifyContent={'space-between'}>
            <Grid sm={5} xs={5} md={3} lg={3} xl={3}>
              <div className="_login_row" style={{ marginBottom: 4 }}>
                <SearchIcon className="_auth_fields_icons" />
                <input
                  type={'text'}
                  placeholder="Search"
                  className="_auth_fields"
                  value={searchText}
                  onChange={(e) => setsearchText(e.target.value)}
                />
              </div>
            </Grid>

            <Grid sm={12} xs={12} md={3} lg={2} xl={2}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginBottom: 5 }}>
                <button
                  className="_btn"
                  style={{ width: '100%', alignSelf: 'flex-end' }}
                  // onClick={() => exportData()}
                  onClick={() => {
                    setviewDetails(true);
                  }}
                >
                  <IoIosAdd style={{ color: 'white' }} size={25} /> Add Card
                </button>
              </div>
            </Grid>
          </Grid>
          <br />
          <Card style={{ borderRadius: 0 }}>
            <Scrollbar>
              <TableContainer>
                {loading ? (
                  <div className="_loading_section">
                    {' '}
                    <CircularProgress color="secondary" />
                  </div>
                ) : (
                  <Table>
                    <UserListHead headLabel={TABLE_HEAD} rowCount={usersList.length} />
                    <TableBody>
                      {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, key) => {
                        const { id, name, description, image } = row;
                        return (
                          <TableRow hover key={id} style={{ fontSize: 14, fontWeight: 400 }}>
                            <TableCell scope="row">
                              <Stack
                                direction="row"
                                alignItems="center"
                                spacing={2}
                                style={{ cursor: 'pointer' }}

                                // onClick={() => {
                                //   setviewWallet(true), setselectedUser(row);
                                // }}
                              >
                                {/* <BiCategory color={'#972b34'} size={20} /> */}
                                <img src={row.image} style={{ height: 30, width: 40 }} />

                                <div>{row.name ? row.name : '--'}</div>
                              </Stack>
                            </TableCell>

                            {/* <TableCell align="left">
                              {console.log(row.merchant)}
                              <div>{row.merchant?.name ? row.merchant.name : '--'}</div>
                            </TableCell> */}
                            <TableCell scope="row">
                              <Stack direction="row" alignItems="center" spacing={2} style={{ cursor: 'pointer' }}>
                                <div>{row.description ? row.description : '--'}</div>
                              </Stack>
                            </TableCell>

                            <TableCell align="left">
                              <div>${row.price}</div>
                            </TableCell>
                            {/* <TableCell align="left">
                              <div className="_tags">{row.program}</div>
                            </TableCell> */}

                            <TableCell align="right">
                              <div className="_action_row">
                                <div
                                  onClick={() => {
                                    setSelected(row), setOpen(true);
                                  }}
                                >
                                  <EditIcon color="#972b34" />
                                </div>
                                {adminUserData.role === 'admin' ? (
                                  <div onClick={() => delete_card(row.id)}>
                                    <DeleteIcon color="#972b34" />
                                  </div>
                                ) : null}
                              </div>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                    {isUserNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filterName} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                )}
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={usersList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
          {/* CHANGE STATUS MODAL */}

          <BootstrapDialog aria-labelledby="customized-dialog-title" open={open} maxWidth="sm" fullWidth={true}>
            <div className="_user_modal_header">
              <div>Edit Card</div>
              <span onClick={() => setOpen(false)}>
                <CloseIcon />
              </span>
            </div>

            <Grid container direction="row" justifyContent="center" alignItems="center" style={{ padding: 20 }}>
              <Grid sm={12} xs={12} md={12} lg={12} xl={12}>
                <Grid container>
                  <Grid sm={12} xs={12} md={12} lg={12} xl={12}>
                    <div className="_verification_row">
                      <label>Name</label>
                      <input
                        className="_verification_fields"
                        value={selected?.name}
                        onChange={(e) => {
                          selected.name = e.target.value;
                          setSelected({ ...selected });
                          setErrorMessage('');
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid sm={12} xs={12} md={12} lg={12} xl={12}>
                    <div className="_verification_row">
                      <label>Description</label>
                      <input
                        className="_verification_fields"
                        value={selected?.description}
                        onChange={(e) => {
                          selected.description = e.target.value;
                          setSelected({ ...selected });
                          setErrorMessage('');
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid sm={12} xs={12} md={12} lg={12} xl={12}>
                    <div className="_verification_row">
                      <label>Price($)</label>
                      <input
                        className="_verification_fields"
                        value={selected?.price}
                        onChange={(e) => {
                          selected.price = e.target.value;
                          setSelected({ ...selected });
                          setErrorMessage('');
                        }}
                      />
                    </div>
                  </Grid>
                  {/* <Grid sm={12} xs={12} md={12} lg={12} xl={12}>
                    <div className="_verification_row">
                      <label>Price</label>
                      <input
                        className="_verification_fields"
                        value={selected?.price}
                        type="number"
                        onChange={(e) => {
                          set_price(e.target.value);
                          setErrorMessage('');
                        }}
                      />
                    </div>
                  </Grid> */}

                  {/* <Grid sm={12} xs={12} md={12} lg={12} xl={12}>
                    <div className="_verification_row">
                      <label>Programme</label>
                      <input
                        className="_verification_fields"
                        value={selected?.program}
                        onChange={(e) => {
                          selected.program = e.target.value
                          setSelected({ ...selected });
                          setErrorMessage('');
                        }}
                      />
                    </div>
                  </Grid> */}
                  <Grid sm={12} xs={12} md={12} lg={12} xl={12}>
                    <div className="_verification_row">
                      <label>Merchant</label>
                      <select
                        className="_verification_fields"
                        onChange={(e) => {
                          set_merchant(e.target.value);
                          setErrorMessage('');
                        }}
                      >
                        {merchant_list.map((val, i) => {
                          return (
                            <option key={i} value={i}>
                              {val.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </Grid>
                  <Grid sm={12} xs={12} md={12} lg={12} xl={12}>
                    <div className="_verification_row">
                      <label>Image</label> <br />
                      {selected?.image && (
                        <img src={selected?.image} style={{ height: 150, width: '100%', marginTop: 10 }} />
                      )}
                      <label
                        htmlFor="_update_promo"
                        className="_verification_fields"
                        style={{ border: '1px solid', display: 'block', textAlign: 'center', marginTop: 10 }}
                      >
                        <AiOutlineFileAdd size={20} color="#972B34" style={{ marginBottom: -5 }} />
                        change card image
                      </label>
                      <input
                        className="_verification_fields"
                        // value={card_img}
                        type="file"
                        id="_update_promo"
                        style={{ height: 50, display: 'none' }}
                        onChange={(e) => upload_image(e.target.files[0], 'edit')}
                      />
                      {/*  */}
                    </div>
                  </Grid>
                  <Grid sm={12} xs={12} md={12} lg={12} xl={12}>
                    <div className="_verification_row">
                      <p style={{ color: 'red', fontSize: 12 }}>{errorMessage}</p>
                    </div>
                  </Grid>
                </Grid>
              </Grid>

              {/* <Grid sm={12} xs={12} md={6} lg={6} xl={6}>
              <div className="_document_view">
                {loadkyc ? (
                  <div className="_loading_section">
                    {' '}
                    <CircularProgress color="secondary" />
                  </div>
                ) : (
                  <>
                    <label>Attached Documents</label>
                    <div className="_documents_row">
                      {kyc.length !== 0 ? (
                        <img src={kyc[0].document.path} style={{ border: '1px solid', height: 200, width: '100%' }} />
                      ) : (
                        <p style={{ textAlign: 'center' }}>Not Found</p>
                      )}
                    </div>
                    <br />

                   
                  </>
                )}
              </div>
              <br />

              {kyc.length !== 0 && (
                <div
                  style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}
                >
                  <button
                    className="_btn"
                    style={{ width: 85, margin: 5, backgroundColor: '#F43131', border: 'none' }}
                    onClick={() => rejectDoc()}
                  >
                    {selected.data?.verificationStatus === 'REJECTED' ? 'Rejected' : 'Reject'}
                  </button>
                  {selected.data?.verificationStatus === 'VERIFIED' && (
                    <button
                      className="_btn"
                      style={{ width: 85, marign: 5, backgroundColor: '#4FCC3B', border: 'none' }}
                      disabled
                    >
                      Accepted
                    </button>
                  )}

                  {selected.data?.verificationStatus === 'PENDING' && (
                    <button
                      className="_btn"
                      style={{ width: 85, marign: 5, backgroundColor: '#4FCC3B', border: 'none' }}
                      onClick={() => acceptDoc()}
                    >
                      Accept
                    </button>
                  )}
                </div>
              )}
            </Grid> */}
            </Grid>

            <DialogActions>
              <div className="_user_modal_footer">
                <button className="_btn outline_btn_modal" onClick={() => setOpen(false)}>
                  Cancel
                </button>
                <button className="_btn" onClick={() => update_card()}>
                  Update
                </button>
              </div>
            </DialogActions>
          </BootstrapDialog>
        </Container>
      ) : (
        <Container>
          <div className="_user_details_title">
            {' '}
            <span>Card</span>/Add Card
          </div>
          <Grid container direction="row" justifyContent="center" alignItems="center">
            <Grid sm={12} xs={12} md={6} lg={6} xl={6}>
              <Grid container>
                <Grid sm={12} xs={12} md={12} lg={12} xl={12}>
                  <div className="_verification_row">
                    <label>Name</label>
                    <input
                      className="_verification_fields"
                      value={card_title}
                      onChange={(e) => {
                        setcard_title(e.target.value);
                        setErrorMessage('');
                      }}
                    />
                  </div>
                </Grid>
                <Grid sm={12} xs={12} md={12} lg={12} xl={12}>
                  <div className="_verification_row">
                    <label>Description</label>
                    <input
                      className="_verification_fields"
                      value={card_desc}
                      onChange={(e) => {
                        setcard_desc(e.target.value);
                        setErrorMessage('');
                      }}
                    />
                  </div>
                </Grid>

                <Grid sm={12} xs={12} md={12} lg={12} xl={12}>
                  <div className="_verification_row">
                    <label>Price($)</label>
                    <input
                      className="_verification_fields"
                      value={card_price}
                      onChange={(e) => {
                        setcard_price(e.target.value);
                        setErrorMessage('');
                      }}
                    />
                  </div>
                </Grid>
                {/* <Grid sm={12} xs={12} md={12} lg={12} xl={12}>
                  <div className="_verification_row">
                    <label>Price</label>
                    <input
                      className="_verification_fields"
                      value={price}
                      type="number"
                      onChange={(e) => {
                        set_price(e.target.value);
                        setErrorMessage('');
                      }}
                    />
                  </div>
                </Grid> */}

                {/* <Grid sm={12} xs={12} md={12} lg={12} xl={12}>
                  <div className="_verification_row">
                    <label>Programme</label>
                    <input
                      className="_verification_fields"
                      value={program}
                      onChange={(e) => {
                        set_program(e.target.value);
                        setErrorMessage('');
                      }}
                    />
                  </div>
                </Grid> */}
                {/* <Grid sm={12} xs={12} md={12} lg={12} xl={12}>
                  <div className="_verification_row">
                    <label>Merchant</label>
                    <select
                      className="_verification_fields"
                      onChange={(e) => {
                        set_merchant(e.target.value);
                        setErrorMessage('');
                      }}
                      value={merchant}
                    >
                      {merchant_list.map((val, i) => {
                        return (
                          <option key={i} value={i}>
                            {val.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </Grid> */}
                <Grid sm={12} xs={12} md={12} lg={12} xl={12}>
                  <div className="_verification_row">
                    <label>Image</label> <br />
                    <label
                      htmlFor="_update_promo"
                      className="_verification_fields"
                      style={{ border: '1px solid', display: 'block', textAlign: 'center', height: 70 }}
                    >
                      <AiOutlineFileAdd size={20} color="#972B34" />
                      <div>upload card image</div>
                    </label>
                    {card_img !== '' && <img src={card_img} style={{ height: 150, width: '100%', marginTop: 10 }} />}
                    <input
                      className="_verification_fields"
                      // value={card_img}
                      type="file"
                      id="_update_promo"
                      style={{ height: 50, display: 'none' }}
                      onChange={(e) => upload_image(e.target.files[0], 'save')}
                    />
                    {/*  */}
                  </div>
                </Grid>
                <Grid sm={12} xs={12} md={12} lg={12} xl={12}>
                  <div className="_verification_row">
                    <p style={{ color: 'red', fontSize: 12 }}>{errorMessage}</p>
                  </div>
                </Grid>

                <Grid sm={12} xs={12} md={12} lg={12} xl={12}>
                  <div className="_verification_row">
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        cursor: 'pointer',
                      }}
                      onClick={() => save_card()}
                    >
                      <button
                        style={{ width: 163 }}
                        className="_btn outline_btn_modal"
                        onClick={() => setviewDetails(false)}
                      >
                        Cancel
                      </button>
                      <button className="_btn" style={{ width: 163 }}>
                        Save
                      </button>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>

            {/* <Grid sm={12} xs={12} md={6} lg={6} xl={6}>
              <div className="_document_view">
                {loadkyc ? (
                  <div className="_loading_section">
                    {' '}
                    <CircularProgress color="secondary" />
                  </div>
                ) : (
                  <>
                    <label>Attached Documents</label>
                    <div className="_documents_row">
                      {kyc.length !== 0 ? (
                        <img src={kyc[0].document.path} style={{ border: '1px solid', height: 200, width: '100%' }} />
                      ) : (
                        <p style={{ textAlign: 'center' }}>Not Found</p>
                      )}
                    </div>
                    <br />

                   
                  </>
                )}
              </div>
              <br />

              {kyc.length !== 0 && (
                <div
                  style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}
                >
                  <button
                    className="_btn"
                    style={{ width: 85, margin: 5, backgroundColor: '#F43131', border: 'none' }}
                    onClick={() => rejectDoc()}
                  >
                    {selected.data?.verificationStatus === 'REJECTED' ? 'Rejected' : 'Reject'}
                  </button>
                  {selected.data?.verificationStatus === 'VERIFIED' && (
                    <button
                      className="_btn"
                      style={{ width: 85, marign: 5, backgroundColor: '#4FCC3B', border: 'none' }}
                      disabled
                    >
                      Accepted
                    </button>
                  )}

                  {selected.data?.verificationStatus === 'PENDING' && (
                    <button
                      className="_btn"
                      style={{ width: 85, marign: 5, backgroundColor: '#4FCC3B', border: 'none' }}
                      onClick={() => acceptDoc()}
                    >
                      Accept
                    </button>
                  )}
                </div>
              )}
            </Grid> */}
          </Grid>
        </Container>
      )}
      <Snackbar open={snackOpen} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={messageType} sx={{ width: '100%' }} style={{ color: '#fff' }}>
          {message}
        </Alert>
      </Snackbar>
    </Page>
  );
}
