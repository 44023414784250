import PropTypes from 'prop-types';
import { alpha, styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import AccountPopover from './AccountPopover';
import Iconify from '../../components/Iconify';
import { HamBurger } from 'src/images/svg';

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 72;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backgroundColor: 'white',
  color: '#5A5A5C',
  borderBottom: '1px solid #D9D9D9',
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
  logged: PropTypes.any,
};

export default function DashboardNavbar({ onOpenSidebar, logged }) {
  const location = useLocation();

  let currentRoutes = location.pathname.split('/');
  if (currentRoutes[currentRoutes.length - 2] === 'editplace') {
    currentRoutes = 'Edit GPS';
  } else {
    currentRoutes = currentRoutes[currentRoutes.length - 1];
  }

  console.log(currentRoutes);
  return (
    <RootStyle>
      <ToolbarStyle>
        <Box sx={{ flexGrow: 1 }} />
        <div
          style={{
            textTransform: 'capitalize',
            marginLeft: 20,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: 150,
          }}
        >
          <div onClick={onOpenSidebar} style={{ cursor: 'pointer' }}>
            <HamBurger />
            {/* <Iconify icon="eva:menu-2-fill" /> */}
          </div>
          <div style={{ fontWeight: 500, fontSize: 24, marginBottom: 4, paddingLeft: 5 }}>{currentRoutes}</div>
        </div>
        <Box sx={{ flexGrow: 3 }} />

        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          <AccountPopover />
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
