import React, { useState, useEffect } from 'react';
import { TableRow, TableBody, TableCell, Container, TablePagination, Grid } from '@mui/material';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { SearchIcon, CloseIcon } from '../images/svg';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import CircularProgress from '@mui/material/CircularProgress';
import moment from 'moment';
import './styles/merchant.css';
import { BASE_URL, UPLOAD_URL } from 'src/config/api';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { IoIosAdd } from 'react-icons/io';
import './styles/promotion.css';
import { TagsInput } from 'react-tag-input-component';
import { HiOutlineLocationMarker } from 'react-icons/hi';
import { BiTime } from 'react-icons/bi';
import { AiOutlineEdit } from 'react-icons/ai';
import { RiDeleteBin6Line } from 'react-icons/ri';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { AiOutlineFileAdd } from 'react-icons/ai';
import { element } from 'prop-types';
import { TbDiscount2 } from 'react-icons/tb';
// import TextEditor from 'src/components/TextEditor';
import RichTextEditor from '../components/TextEditor';

// import { element } from 'prop-types';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));
// ----------------------------------------------------------------------
const TABLE_HEAD = [
  { id: 'Category', label: 'Category', alignRight: false },
  { id: 'Description', label: 'Description', alignRight: false },
  { id: 'Tags', label: 'Tags', alignRight: false },
  { id: 'Action', label: 'Action', alignRight: false },
];
// ----------------------------------------------------------------------
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
export default function Promotion() {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [selected, setSelected] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [usersList, setusersList] = useState([]);
  const [viewDetail, setviewDetails] = useState(false);
  const [loading, setloading] = useState(false);
  const [searchText, setsearchText] = useState('');
  const [adminUserData, setAdminUserData] = useState(JSON.parse(localStorage.getItem('adminUserData')));
  const [merchant, setmerchant] = useState([]);
  const [selectedMerchant, setselectedMerchant] = useState(null);
  const [promotion_name, setpromotion_name] = useState('');
  const [promotion_code, setpromotion_code] = useState('');
  const [categoryList, setcategoryList] = useState([]);
  const [landmarksList, setlandmarksList] = useState([]);

  const [selected_cat, setselected_cat] = useState(null);
  const [selected_landmark, setselected_landmark] = useState(null);

  const [selected_promotion, setselected_promotion] = useState(0);
  const [selected_tag, setselected_tag] = useState(null);
  const [usercard, setusercard] = useState(null);
  const [selected_card, setselected_card] = useState([]);
  const [promotionCodes, setpromotionCodes] = useState([]);

  const [promotion_desc, set_promotion_desc] = useState('');
  const [promotion_img, set_promotion_img] = useState('');
  const [promotion_period, set_promotion_period] = useState(new Date());
  const [promotion_satrt_date, set_promotion_satrt_date] = useState('');
  const [promotion_end_date, set_promotion_end_date] = useState('');
  const [buildingName, set_buildingName] = useState('');
  const [buildingNumber, set_buildingNumber] = useState('');
  const [streetName, set_streetName] = useState('');
  const [landmark, set_landmark] = useState('');
  const [city, set_city] = useState('');
  const [state, set_state] = useState('');
  const [postcode, set_postcode] = useState('');

  
  const [errorMessage, setErrorMessage] = useState('');
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('success');
  const [snackOpen, setSnackOpen] = useState(false);

  const fetchMerchant = async (search) => {
    axios({
      method: 'get',
      url: `${BASE_URL}/Merchant`,
      params: {
        Authorization: adminUserData?.token,
      },
    })
      .then((res) => {
        setmerchant(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetch_cards = async (search) => {
    setloading(true);
    axios({
      method: 'get',
      url: `${BASE_URL}/cards`,
      withCredentails: false,
      params: {
        Authorization: adminUserData?.token,
      },
    })
      .then((res) => {
        setloading(false);
        setusercard([...res.data]);
        setselected_card([res.data[0]]);
        // setusersList([...res.data]);
      })
      .catch((err) => {
        setloading(false);
      });
  };


  const fetchCategory = async (search) => {
    setloading(true);
    axios({
      method: 'get',
      url: `${BASE_URL}/category`,
    })
      .then((res) => {
        setcategoryList([...res.data]);
      })
      .catch((err) => {
        console.log(err);
      });
  };


  const fetchLandmarks = async (search) => {
    setloading(true);
    axios({
      method: 'get',
      url: `${BASE_URL}/Landmarks`,
    })
      .then((res) => {
        setlandmarksList([...res.data]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchUsers = async (search) => {
    setloading(true);
    axios({
      method: 'get',
      url: `${BASE_URL}/Deals`,
      withCredentails: false,
      params: {
        Authorization: adminUserData.token,
      },
    })
      .then((res) => {
        setloading(false);
        setusersList([...res.data]);
      })
      .catch((err) => {
        console.log(res.response.data);

        setloading(false);
      });
  };

  useEffect(() => {
    (async () => {
      await fetch_cards();
      await fetchMerchant();
      await fetch_promotion();
      await fetchLandmarks()
      await fetchCategory();
      await fetchUsers();
    })();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const fetch_promotion = async (search) => {
    axios({
      method: 'get',
      url: `${BASE_URL}/Discount`,
      // params: {
      //   Authorization: adminUserData?.token,
      // },
    })
      .then((res) => {
        setpromotionCodes(res.data);
      })
      .catch((err) => {
        console.log('==========>>err=====>>>', err.response.data);
        setloading(false);
      });
  };
  console.log("////////////////",selected)

  const updatePromotion = async () => {
 


    if (selected.merchant === 'Select') {
      setErrorMessage('Please select Merchant');
    } else if (selected.name === '') {
      setErrorMessage('Please enter Name');
    } else if (selected.code === '') {
      setErrorMessage('Please enter Promotion Code');
    }
    // else if (selected) {
    //   setErrorMessage('Please Select Category');
    // }
    else if (selected?.tags.length === 0) {
      setErrorMessage('Please Select tags');
    } else if (selected.description === '') {
      setErrorMessage('Please enter Promotion Description');
    } else if (selected.location === '') {
      setErrorMessage('Please enter Promotion Location');
    } else if (selected.image === '') {
      setErrorMessage('Please add Promotion Image');
    }
    // else if (selected.validityPeriod === '') {
    //   setErrorMessage('Please add Promotion Validity Period');
    // }
    else {
      var _obj = categoryList[selected_cat];
      for (var i = 0; i < selected_card.length; i++) {
        delete selected_card[i].userCards;
        delete selected_card[i].promotions;
        delete selected_card[i].deletedAt;
        delete selected_card[i].deals;
      }
      _obj.subcategories = [];
      delete _obj.promotions;
      delete _obj.deals;
      delete promotionCodes[selected_promotion].updatedAt;
      delete promotionCodes[selected_promotion].createdAt;
      delete promotionCodes[selected_promotion].deletedAt;
      delete promotionCodes[selected_promotion].deals;
      let _cards_ids = selected_card.map(v => v.id);
      console.log(promotionCodes[selected_promotion])
      let axiosConfig = {
        name: selected?.name,
        merchant: {
          id: merchant[selectedMerchant].id,
          name: merchant[selectedMerchant].name,
        },
        discount: promotionCodes[selected_promotion],
        description: selected?.description,
        category_ids: [categoryList[selected_cat].id],
        landmark_id:landmarksList[selected_landmark].id,
        card_ids:_cards_ids,
        image: selected.image,
        code: selected.code,
        tags: selected?.tags,
        location: selected?.location,
        startDate: selected?.startDate,
        endDate: selected?.endDate,
        validityPeriod: {
          type: 'tenth_day_of_month',
        },
      };
      console.log(axiosConfig);
      await axios
        .patch(`${BASE_URL}/Deals/${selected.id}`, axiosConfig)
        .then((res) => {
          setOpen(false);
          fetchUsers();
          setselected_card([]);
          setSnackOpen(true);
          setMessage('Promotion updated successfully');
          setMessageType('success');
          setOpen(false);
        })
        .catch((err) => {
          console.log(err.response.data);
        });
    }
  };

  const upload_image = (_file, type) => {
    var formData = new FormData();
    formData.append('file', _file);
    formData.append('upload_preset', 'ml_default');
    formData.append('api_key', '767293436452984');

    axios({
      method: 'post',
      url: UPLOAD_URL,
      data: formData,
    })
      .then(function (response) {
        if (type === 'edit') {
          selected.image = response.data.url;
          setSelected({ ...selected });
          setErrorMessage('');
        } else {
          set_promotion_img(response.data.url);
          setErrorMessage('');
        }
      })
      .catch(function (error) {
        console.log('////error/////', error);
      });
  };

  const delete_category = async (_id) => {
    var _confirm = confirm("Are you sure you want to delete or cancel to go back")
if(_confirm){
    let axiosConfig = {
      Authorization: adminUserData?.token,
    };
    await axios
      .delete(`${BASE_URL}/Deals/${_id}`, axiosConfig)

      .then((res) => {
        fetchUsers();
        setSnackOpen(true);
        setMessage('Deals deleted successfully');
        setMessageType('success');
      })
      .catch((err) => {
        console.log(err.data);
      });
    }
  };

  const VoucerCodeGenerator = (length) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  };



  const savePromotion = async () => {
    if (!selectedMerchant) {
      setErrorMessage('Please select Merchant');
    } else if (selected_card.length === 0) {
      setErrorMessage('Please select card');
    } else if (promotion_name === '') {
      setErrorMessage('Please enter name');
    } else if (!selected_cat) {
      setErrorMessage('Please select category');
    } else if (selected_promotion === null) {
      setErrorMessage('Please select deal promotion');
    } else if (selected_cat && !selected_tag) {
      setErrorMessage('Please select tags');
    } else if (promotion_desc === '') {
      setErrorMessage('Please enter deal description');
    } else if (buildingNumber === '') {
      setErrorMessage('Please enter building number');
    } else if (streetName === '') {
      setErrorMessage('Please enter street name');
    } else if (buildingName === '') {
      setErrorMessage('Please enter building name');
    } else if (city === '') {
      setErrorMessage('Please enter city name');
    } else if (state === '') {
      setErrorMessage('Please enter state name');
    }
    else if (postcode === '') {
      setErrorMessage('Please enter postal code');
    }
    
    else if (selected_landmark === '') {
      setErrorMessage('Please enter landmark');
    } else if (promotion_img === '') {
      setErrorMessage('Please add deal image');
    }
    else if (promotion_satrt_date === '') {
      setErrorMessage('Please select deal start date');
    } else if (promotion_end_date === '') {
      setErrorMessage('Please select deal end date');
    } else {

   const geoaddress = await getGeoData()
      var _obj = categoryList[selected_cat];
      _obj.subcategories = [];
      let axiosConfig = {
        Authorization: adminUserData?.token,
      };

      delete categoryList[selected_cat].promotions;
      delete categoryList[selected_cat].deals;
      for (var i = 0; i < selected_card.length; i++) {
        delete selected_card[i].userCards;
        delete selected_card[i].promotions;
        delete selected_card[i].deletedAt;
        delete selected_card[i].deals;
        delete selected_card[i].description;
      }
      delete promotionCodes[selected_promotion].updatedAt;
      delete promotionCodes[selected_promotion].createdAt;
      delete promotionCodes[selected_promotion].deals;
      delete promotionCodes[selected_promotion].deletedAt

      var cards_id = selected_card.map((v) => v.id);

      await axios
        .post(
          `${BASE_URL}/Deals`,
          {
            name: promotion_name,
            description: promotion_desc,
            image: promotion_img,
            building_name:buildingName,
            merchant: {
              id: merchant[selectedMerchant].id,
              name: merchant[selectedMerchant].name,
            },
            discount: promotionCodes[selected_promotion],
            card_ids: cards_id,
            landmark_id:landmarksList[selected_landmark].id,
            code: VoucerCodeGenerator(6),
            category_ids: [categoryList[selected_cat].id],
            validityPeriod: {
              type: 'tenth_day_of_month',
            },
            tags: selected_tag,
            location:
              buildingNumber + ', ' + streetName + ', ' + buildingName + ', ' + city + ', ' + state + ', '+ postcode +', '+ landmarksList[selected_landmark].name,
              coordinates:{
              latitude:geoaddress.lat,
              longitude:geoaddress.lng
            },
              startDate: promotion_satrt_date,
            endDate: promotion_end_date,
          },
          axiosConfig
        )
        .then((res) => {
          setpromotion_name('');
          setpromotion_code('');
          set_promotion_desc('');
          setviewDetails(false);
          set_promotion_img('');
          set_promotion_period('');
          setselected_cat(null);
          setselected_card([usercard[0]]);
          setselectedMerchant(null);
          setErrorMessage('');
          setSnackOpen(true);
          setMessage('Deals added successfully');
          setMessageType('success');
          // set_
          fetchUsers();
        })
        .catch((err) => {
          console.log("=====err=====>",err.response.data)

          setErrorMessage(err.response.data.message);
        });
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const promo_handlechange = (v, type) => {
    var _data = selected;
    if (type === 'name') {
      _data.name = v;
    } else if (type === 'code') {
      _data.code = v;
    } else if (type === 'cat') {
      selected.categories = v;
    } else if (type === 'tags') {
      _data.tags = v;
      // selected.tags = v;
    } else if (type === 'desc') {
      _data.description = v;
    } else if (type === 'location') {
      _data.location = v;
    } else if (type === 'image') {
      _data.image = v;
    } else if (type === 'period') {
      // selected.validityPeriod.type = v;
      // setSelected(_data);
    }
    console.log(type, v);
    setSelected({ ..._data });
    setErrorMessage('');
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackOpen(false);
  };


const getGeoData=()=>{
  const locationName = buildingNumber + ',' + streetName + ',' + buildingName + ',' + city + ',' + state + ',' + landmark; // Replace with the location name you want to geocode
const apiKey = 'AIzaSyAH6yeKHAht4rNeOZsJZQtWTuCLGVvzsMk';

const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${locationName}&key=${apiKey}`;

return axios.get(apiUrl)
  .then(response => {
    if (response.data.status === 'OK') {
      const location = response.data.results[0].geometry.location;
      const latitude = location.lat;
      const longitude = location.lng;
      return location
    } else {
      console.log('Geocoding API request failed');
    }
  })
  .catch(error => {
    // return location
    console.error('Error making geocoding API request:', error.response.data);
  });

}


 

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - usersList.length) : 0;

  // console.log(start_date);

  const filteredUsers = usersList.filter((users) => {
    return users.name?.toLowerCase().includes(searchText.toLowerCase());
  });

  const isUserNotFound = filteredUsers.length === 0;

  selected_cat?.tags?.map((element) => {
    return element;
  });
  let selectedTags = categoryList[selected_cat]?.tags;
  console.log('>>>>>>>>>>>>>>>landmarksList<<<<<<<<<<<<<<<<', landmarksList[selected_landmark]);

  return (
    <Page title="Users">
      {!viewDetail ? (
        <Container>
          <Grid container justifyContent={'space-between'}>
            <Grid sm={5} xs={5} md={3} lg={3} xl={3}>
              <div className="_login_row" style={{ marginBottom: 4 }}>
                <SearchIcon className="_auth_fields_icons" />
                <input
                  type={'text'}
                  placeholder="Search"
                  className="_auth_fields"
                  value={searchText}
                  onChange={(e) => setsearchText(e.target.value)}
                />
              </div>
            </Grid>

            <Grid sm={12} xs={12} md={3} lg={2} xl={2}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginBottom: 5 }}>
                <button
                  className="_btn"
                  style={{ width: '100%', alignSelf: 'flex-end' }}
                  onClick={() => {
                    setviewDetails(true);
                  }}
                >
                  <IoIosAdd style={{ color: 'white' }} size={25} /> Add Deal
                </button>
              </div>
            </Grid>
          </Grid>
          <br />
          <Scrollbar>
            <div>
              {loading ? (
                <div className="_loading_section">
                  {' '}
                  <CircularProgress color="secondary" />
                </div>
              ) : (
                <div>
                  <div container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, key) => {
                      const { id, name, description, image } = row;
                      return (
                     
                        <div className="_list">
                          <div className="_promotion_image_section">
                            <img src={row.image} className="_promotion_image" />
                            <div className="_tags _cat_tag">Code: {row.code}</div>
                          </div>

                          <div className="_middle_section">
                            <h4 className="_section_heading">{row.name}</h4>
                            <div className="_promotion_desc" dangerouslySetInnerHTML={{ __html: row.description }} />

                            <p className="_location_text">
                              <TbDiscount2 style={{ marginRight: 5 }} size={16} /> Discount : {row.discount?.value}
                            </p>
                            <p className="_location_text">
                              <HiOutlineLocationMarker style={{ marginRight: 5 }} /> {row.location}
                            </p>

                            <p className="_location_text">
                              <BiTime style={{ marginRight: 5 }} /> 20 dec 2020
                            </p>
                            <p>
                              {row?.tags?.map((val, i) => {
                                return (
                                  <div key={i} className="_tags" style={{ marginLeft: 0, marginRight: 5 }}>
                                    {val}
                                  </div>
                                );
                              })}
                            </p>
                            <p className="_merchant_name">Merchant: {row.merchant?.name}</p>
                          </div>

                          <div className="_buttons_section">
                            <button
                              className="_btn _promotion_btn"
                              style={{ width: '100%', alignSelf: 'flex-end' }}
                              onClick={() => {
                                setOpen(true);
                                setSelected(row);
                                merchant.forEach((element, index) => {
                                  if (element.id === row.merchant.id) {
                                    setselectedMerchant(index);
                                  }
                                });
                                categoryList.forEach((element, index) => {
                                  if (element.id === row.categories[0].id) {
                                    setselected_cat(index);
                                  }
                                });

                                promotionCodes.forEach((element, index) => {
                                  if (element.value === row.discount.value) {
                                    setselected_promotion(index);
                                  }
                                });
                                setselected_card([...row.cards]);
                              }}
                            >
                              <AiOutlineEdit style={{ color: 'white' }} /> Edit
                            </button>
                            {adminUserData.role === 'admin' ? (
                              <button
                                className="_btn _promotion_btn"
                                style={{ width: '100%', alignSelf: 'flex-end' }}
                                // onClick={() => exportData()}
                                onClick={() => delete_category(row.id)}
                              >
                                <RiDeleteBin6Line style={{ color: 'white' }} /> Delete
                              </button>
                            ) : null}
                          </div>
                        </div>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </div>
                  {isUserNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </div>
              )}
            </div>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={usersList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          {/* CHANGE STATUS MODAL */}

          <BootstrapDialog aria-labelledby="customized-dialog-title" open={open} maxWidth="sm" fullWidth={true}>
            <div className="_user_modal_header">
              <div>Edit Deal</div>
              <span onClick={() => setOpen(false)}>
                <CloseIcon />
              </span>
            </div>

            <Grid container direction="row" justifyContent="center" alignItems="center" style={{ padding: 20 }}>
              <Grid container>
                {/* <Grid sm={12} xs={12} md={12} lg={12} xl={12}>
                  <div className="_verification_row">
                    <label>Select Merchant</label>
                    <select
                      className="_verification_fields"
                      value={selected?.merchant}
                      onChange={(e) => promo_handlechange(e.target.value, 'merchant')}
                    >
                      <option>Select</option>
                      {merchant.map((val, i) => {
                        return (
                          <option key={i} value={val}>
                            {val.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </Grid> */}
                <Grid sm={12} xs={12} md={12} lg={12} xl={12}>
                  <div className="_verification_row">
                    <label>*Select Merchant</label>
                    <select
                      className="_verification_fields"
                      value={selectedMerchant}
                      onChange={(e) => {
                        setselectedMerchant(e.target.value);
                        setErrorMessage('');
                      }}
                    >
                      <option>Select</option>
                      {merchant.length > 0 && merchant.map((val, i) => {
                        return (
                          <option key={i} value={i}>
                            {val.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </Grid>
                {usercard && (
                  <Grid sm={12} xs={12} md={12} lg={12} xl={12}>
                    <div className="_verification_row">
                      <label>*Select Card</label>
                      <Autocomplete
                        multiple
                        limitTags={2}
                        id="multiple-limit-tags"
                        onChange={(event, value) => {
                          setselected_card(value);
                          setErrorMessage('');
                        }} // prints the selected value
                        value={selected_card}
                        options={usercard ? usercard:[]}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                          <TextField onChange={(e) => params} {...params} label="" placeholder="Select card" />
                        )}
                      />
                    </div>
                  </Grid>
                )}
                <Grid sm={12} xs={12} md={12} lg={12} xl={12}>
                  <div style={{ paddingLeft: 10, paddingRight: 10 }}>
                    <input
                      value="allCards"
                      onChange={(e) => {
                        if (e.target.checked) {
                          setselected_card(usercard);
                        } else {
                          setselected_card([usercard[0]]);
                        }
                      }}
                      type="checkbox"
                    />
                    <label style={{ marginLeft: 10 }}>All Cards</label>
                  </div>
                </Grid>

                {/* <Grid sm={12} xs={12} md={12} lg={12} xl={12}>
                  <div className="_verification_row">
                    <label>Select Card</label>
                    <select
                      className="_verification_fields"
                      value={selected?.merchant}
                      onChange={(e) => promo_handlechange(e.target.value, 'merchant')}
                    >
                      <option>Select</option>
                      {merchant.map((val, i) => {
                        return (
                          <option key={i} value={val}>
                            {val.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </Grid> */}

                <Grid sm={12} xs={12} md={6} lg={6} xl={6}>
                  <div className="_verification_row">
                    <label>Deal Title</label>
                    <input
                      className="_verification_fields"
                      value={selected?.name}
                      onChange={(e) => promo_handlechange(e.target.value, 'name')}
                    />
                  </div>
                </Grid>

                <Grid sm={12} xs={12} md={6} lg={6} xl={6}>
                  <div className="_verification_row">
                    <label>Code</label>
                    <input
                      className="_verification_fields"
                      value={selected?.code}
                      onChange={(e) => promo_handlechange(e.target.value, 'code')}
                    />
                  </div>
                </Grid>

                <Grid sm={12} xs={12} md={12} lg={12} xl={12}>
                  <div className="_verification_row">
                    <label>*Select Category</label>
                    <select
                      className="_verification_fields"
                      // value={selected?.categories}
                      // onChange={(e) => promo_handlechange(e.target.value, 'cat')}
                      value={selected_cat}
                      onChange={(e) => {
                        setselected_cat(e.target.value);
                        setErrorMessage('');
                      }}
                    >
                      <option value={"-1"}>Select</option>
                      {categoryList.map((val, i) => {
                        return (
                          <option key={i} value={i}>
                            {val.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </Grid>

                <Grid sm={12} xs={12} md={12} lg={12} xl={12}>
                  <div className="_verification_row">
                    <label>*Select Landmark</label>
                    <select
                      className="_verification_fields"
                      // value={selected?.categories}
                      // onChange={(e) => promo_handlechange(e.target.value, 'cat')}
                      value={selected_landmark}
                      onChange={(e) => {
                        setselected_landmark(e.target.value);
                        setErrorMessage('');
                      }}
                    >
                      <option value={0}>Select</option>
                      {landmarksList.map((val, i) => {
                        return (
                          <option key={i} value={i}>
                            {val.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </Grid>

                <Grid sm={12} xs={12} md={12} lg={12} xl={12}>
                  <div className="_verification_row">
                    <label>Select Promotion</label>
                    <select
                      className="_verification_fields"
                      // value={selected?.categories}
                      // onChange={(e) => promo_handlechange(e.target.value, 'cat')}
                      value={selected_promotion}
                      onChange={(e) => {
                        setselected_promotion(e.target.value);
                        setErrorMessage('');
                      }}
                    >
                      <option value={"Select"}>Select</option>
                      {promotionCodes?.map((val, i) => {
                        return (
                          <option key={i} value={i}>
                            {val.value}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </Grid>
                {selected?.tags !== 0 && (
                  // <Grid sm={12} xs={12} md={12} lg={12} xl={12}>
                  //   <div className="_verification_row">
                  //     <label>Category Tags</label>
                  //     <TagsInput
                  //       className="rti--container"
                  //       value={selected?.tags}
                  //       onChange={(e) => promo_handlechange(e, 'tags')}
                  //       name="tags"
                  //       placeHolder="Enter ..."
                  //     />
                  //   </div>
                  // </Grid>

                  <Grid sm={12} xs={12} md={12} lg={12} xl={12}>
                    <div className="_verification_row">
                      <label>*Category Tags</label>
                      <Autocomplete
                        multiple
                        limitTags={2}
                        id="multiple-limit-tags"
                        onChange={(event, value) => {
                          selected.tags = value;
                          setSelected({ ...selected });
                          setErrorMessage('');
                        }}
                        value={ selected?.tags ? selected?.tags:[]}
                        options={selectedTags}
                        getOptionLabel={(option) => option}
                        renderInput={(params) => (
                          <TextField onChange={(e) => params} {...params} label="" placeholder="Select tag" />
                        )}
                        // sx={{ width: '500px' }}
                        style={{ width: '100%' }}
                      />
                    </div>
                  </Grid>
                )}

                <Grid sm={12} xs={12} md={12} lg={12} xl={12}>
                  <div className="_verification_row">
                    <label>*Description</label>
                    {/* <input
                      className="_verification_fields"
                      value={selected?.description}
                      style={{ height: 50 }}
                      onChange={(event, value) => promo_handlechange(e.target.value, 'desc')}
                    /> */}
                      <RichTextEditor
                      initialContent={selected?.description}
                      onChange={(content) => {
                        promo_handlechange(content, 'desc')
                      }}
                    />
                  </div>
                </Grid>

                <Grid sm={12} xs={12} md={6} lg={6} xl={6}>
                  <div className="_verification_row">
                    <label>Location</label>
                    <input
                      className="_verification_fields"
                      style={{ height: 50 }}
                      value={selected?.location}
                      onChange={(event, value) => promo_handlechange(event.target.value, 'location')}
                    />
                  </div>
                </Grid>

                {/* <Grid sm={12} xs={12} md={6} lg={6} xl={6}>
                  <div className="_verification_row">
                    <label>Validity Period</label>
                    <input
                      className="_verification_fields"
                      type="date"
                      style={{ height: 50 }}
                      onChange={(event, value) => promo_handlechange(value, 'period')}
                    />
                  </div>
                </Grid> */}
                <Grid sm={12} xs={12} md={6} lg={6} xl={6}>
                  <div className="_verification_row">
                    <label>Start Date</label>
                    <input
                      className="_verification_fields"
                      value={selected?.startDate}
                      type="date"
                      style={{ height: 50 }}
                      onChange={(e) => {
                        selected.startDate = e.target.value;
                        setSelected(e.target.value);
                        setErrorMessage('');
                      }}
                    />
                  </div>
                </Grid>
                <Grid sm={12} xs={12} md={6} lg={6} xl={6}>
                  <div className="_verification_row">
                    <label>End Date</label>
                    <input
                      className="_verification_fields"
                      value={selected?.endDate}
                      type="date"
                      style={{ height: 50 }}
                      onChange={(e) => {
                        selected.endDate = e.target.value;
                        setSelected(e.target.value);
                        setErrorMessage('');
                      }}
                    />
                  </div>
                </Grid>

                <Grid sm={12} xs={12} md={12} lg={12} xl={12}>
                  <div className="_verification_row">
                    <label>Image</label>
                    <label htmlFor="_update_promo">
                      <img src={selected?.image} style={{ height: 150, width: '100%' }} />
                    </label>
                    <input
                      className="_verification_fields"
                      // value={cat_desc}
                      type="file"
                      id="_update_promo"
                      style={{ height: 50, display: 'none' }}
                      onChange={(e) => upload_image(e.target.files[0], 'edit')}
                      // onChange={(event, value) => promo_handlechange(event.target.value, 'image')}
                    />
                  </div>
                </Grid>
                <Grid sm={12} xs={12} md={12} lg={12} xl={12}>
                  <div className="_verification_row">
                    <p style={{ color: 'red', fontSize: 12 }}>{errorMessage}</p>
                  </div>
                </Grid>
              </Grid>
            </Grid>

            <DialogActions>
              <div className="_user_modal_footer">
                <button className="_btn outline_btn_modal" onClick={() => setOpen(false)}>
                  Cancel
                </button>
                <button className="_btn" onClick={() => updatePromotion()}>
                  Update
                </button>
              </div>
            </DialogActions>
          </BootstrapDialog>
        </Container>
      ) : (
        <Container>
          <div className="_user_details_title">
            {' '}
            <span>Deal</span>/Add Deal
          </div>
          <Grid container direction="row" justifyContent="center" alignItems="center">
            <Grid sm={12} xs={12} md={6} lg={6} xl={6}>
              <Grid container>
                <Grid sm={12} xs={12} md={12} lg={12} xl={12}>
                  <div className="_verification_row">
                    <label>*Select Merchant</label>
                    <select
                      className="_verification_fields"
                      value={selectedMerchant}
                      onChange={(e) => {
                        // fetch_user_cards(merchant[e.target.value].id);
                        setselectedMerchant(e.target.value);
                        setErrorMessage('');
                      }}
                    >
                      <option>Select</option>
                      {merchant.map((val, i) => {
                        return (
                          <option key={i} value={i}>
                            {val.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </Grid>
                {usercard && (
                  <Grid sm={12} xs={12} md={12} lg={12} xl={12}>
                    <div className="_verification_row">
                      <label>*Select Card</label>
                      <Autocomplete
                        multiple
                        limitTags={2}
                        id="multiple-limit-tags"
                        onChange={(event, value) => {
                          setselected_card(value);
                          setErrorMessage('');
                        }}
                        value={selected_card}
                        options={usercard ? usercard:[]}
                        getOptionLabel={(option) => option && option.name}
                        renderInput={(params) => (
                          <TextField onChange={(e) => params} {...params} label="" placeholder="Select card" />
                        )}
                        // sx={{ width: '500px' }}
                      />
                    </div>
                  </Grid>
                )}
                <Grid sm={12} xs={12} md={12} lg={12} xl={12}>
                  <div style={{ paddingLeft: 10, paddingRight: 10 }}>
                    <input
                      value="allCards"
                      onChange={(e) => {
                        if (e.target.checked) {
                          setselected_card(usercard);
                        } else {
                          setselected_card([usercard[0]]);
                        }
                      }}
                      type="checkbox"
                    />
                    <label style={{ marginLeft: 10 }}>All Cards</label>
                  </div>
                </Grid>
                <Grid sm={12} xs={12} md={12} lg={12} xl={12}>
                  <div className="_verification_row">
                    <label>*Name</label>
                    <input
                      className="_verification_fields"
                      value={promotion_name}
                      onChange={(e) => {
                        setpromotion_name(e.target.value);
                        setErrorMessage('');
                      }}
                    />
                  </div>
                </Grid>
                {/* <Grid sm={12} xs={12} md={12} lg={12} xl={12}>
                  <div className="_verification_row">
                    <label>Code</label>
                    <input
                      className="_verification_fields"
                      value={promotion_code}
                      onChange={(e) => {
                        setpromotion_code(e.target.value);
                        setErrorMessage('');
                      }}
                    />
                  </div>
                </Grid> */}
                <Grid sm={12} xs={12} md={12} lg={12} xl={12}>
                  <div className="_verification_row">
                    <label>*Select Category</label>
                    {/* <input
                      className="_verification_fields"
                      value={promotion_name}
                      onChange={(e) => setpromotion_name(e.target.value)}
                    /> */}
                    <select
                      className="_verification_fields"
                      value={selected_cat}
                      onChange={(e) => {
                        if (e.target.value !== 'select') {
                          setselected_cat(e.target.value);
                          setErrorMessage('');
                        }
                      }}
                    >
                      <option value={"Select"}>Select</option>
                      {categoryList.map((val, i) => {
                        return (
                          <option key={i} value={i}>
                            {val.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </Grid>

                <Grid sm={12} xs={12} md={12} lg={12} xl={12}>
                  <div className="_verification_row">
                    <label>*Select Landmark</label>
                    <select
                      className="_verification_fields"
                      // value={selected?.categories}
                      // onChange={(e) => promo_handlechange(e.target.value, 'cat')}
                      value={selected_landmark}
                      onChange={(e) => {
                        setselected_landmark(e.target.value);
                        setErrorMessage('');
                      }}
                    >
                      <option value={"Select"}>Select</option>
                      {landmarksList.map((val, i) => {
                        return (
                          <option key={i} value={i}>
                            {val.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </Grid>

                <Grid sm={12} xs={12} md={12} lg={12} xl={12}>
                  <div className="_verification_row">
                    <div
                      style={{
                        flexDirection: 'row',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%',
                      }}
                    >
                      <label>*Promotion</label>
                    </div>
                    {/* <input
                      className="_verification_fields"
                      value={promotion_name}
                      onChange={(e) => setpromotion_name(e.target.value)}
                    /> */}
                    <select
                      className="_verification_fields"
                      value={selected_promotion}
                      onChange={(e) => {
                        if (e.target.value !== 'select') {
                          setselected_promotion(e.target.value);
                          setErrorMessage('');
                        }
                      }}
                    >
                      <option value={"Select"}>Select</option>
                      {promotionCodes.map((val, i) => {
                        return (
                          <option key={i} value={i}>
                            {val.value}
                          </option>
                        );
                      })}
                    </select>
                    {/* <div style={{ textAlign: 'right', color: '#5c5cb6', textDecoration: 'underline' }}>
                      <label style={{ cursor: 'pointer' }}>Update promotion</label>
                    </div> */}
                  </div>
                </Grid>
                {selected_cat && (
                  <Grid sm={12} xs={12} md={12} lg={12} xl={12}>
                    <div className="_verification_row">
                      <label>*Category Tags</label>
                      <Autocomplete
                        multiple
                        limitTags={2}
                        id="multiple-limit-tags"
                        onChange={(event, value) => {
                          setselected_tag(value);
                          setErrorMessage('');
                        }}
                        options={selectedTags ? selectedTags:[]}
                        getOptionLabel={(option) => option}
                        renderInput={(params) => (
                          <TextField onChange={(e) => params} {...params} label="" placeholder="Select tag" />
                        )}
                        sx={{ width: '500px' }}
                      />
                    </div>
                  </Grid>
                )}
                <Grid sm={12} xs={12} md={12} lg={12} xl={12}>
                  <div className="_verification_row">
                    <label>*Description</label>
                    {/* <input
                      className="_verification_fields"
                      value={promotion_desc}
                      style={{ height: 150 }}
                      onChange={(e) => {
                        set_promotion_desc(e.target.value);
                        setErrorMessage('');
                      }}
                    /> */}
                    {/* <TextField
                      multiline
                      minRows={4}
                      fullWidth={true}
                      classes="_verification_fields"
                      value={promotion_desc}
                      style={{ display: 'block', backgroundColor: '#FCFCFC', width: '100%' }}
                      onChange={(e) => {
                        set_promotion_desc(e.target.value);
                        setErrorMessage('');
                      }}
                    /> */}
                     <RichTextEditor
                      initialContent={promotion_desc}
                      onChange={(content) => {
                        set_promotion_desc(content);
                        setErrorMessage('')
                      }}
                    />
                  </div>
                </Grid>
                <Grid sm={12} xs={12} md={12} lg={12} xl={12}>
                  <div className="_verification_row">
                    <label>*City</label>
                    <input
                      className="_verification_fields"
                      style={{ height: 50 }}
                      placeholder="Eg: Eufaula"
                      value={city}
                      onChange={(e) => {
                        set_city(e.target.value);
                        setErrorMessage('');
                      }}
                    />
                  </div>
                </Grid>

                <Grid sm={12} xs={12} md={12} lg={12} xl={12}>
                  <div className="_verification_row">
                    <label>*State</label>
                    <input
                      className="_verification_fields"
                      style={{ height: 50 }}
                      placeholder="Eg: Colorado"
                      value={state}
                      onChange={(e) => {
                        set_state(e.target.value);
                        setErrorMessage('');
                      }}
                    />
                  </div>
                </Grid>

                <Grid sm={12} xs={12} md={12} lg={12} xl={12}>
                  <div className="_verification_row">
                    <label>*Postal Code</label>
                    <input
                      className="_verification_fields"
                      style={{ height: 50 }}
                      placeholder="Eg: 69230"
                      value={postcode}
                      onChange={(e) => {
                        set_postcode(e.target.value);
                        setErrorMessage('');
                      }}
                    />
                  </div>
                </Grid>

                <Grid sm={12} xs={12} md={12} lg={6} xl={6}>
                  <div className="_verification_row">
                    <label>*Building / Block / House No.</label>
                    <input
                      className="_verification_fields"
                      style={{ height: 50 }}
                      placeholder="Eg: 100"
                      value={buildingNumber}
                      onChange={(e) => {
                        set_buildingNumber(e.target.value);
                        setErrorMessage('');
                      }}
                    />
                  </div>
                </Grid>

                <Grid sm={12} xs={12} md={12} lg={6} xl={6}>
                  <div className="_verification_row">
                    <label>*Street Name</label>
                    <input
                      className="_verification_fields"
                      style={{ height: 50 }}
                      value={streetName}
                      placeholder="Eg: Woodlands Avenue 7"
                      onChange={(e) => {
                        set_streetName(e.target.value);
                        setErrorMessage('');
                      }}
                    />
                  </div>
                </Grid>

                <Grid sm={12} xs={12} md={12} lg={12} xl={12}>
                  <div className="_verification_row">
                    <label>*Building Name</label>
                    <input
                      className="_verification_fields"
                      style={{ height: 50 }}
                      value={buildingName}
                      placeholder="Eg: Paya Lebar Square"
                      onChange={(e) => {
                        set_buildingName(e.target.value);
                        setErrorMessage('');
                      }}
                    />
                  </div>
                </Grid>

                {/* <Grid sm={12} xs={12} md={12} lg={6} xl={6}>
                  <div className="_verification_row">
                    <label>Landmark</label>
                    <input
                      className="_verification_fields"
                      style={{ height: 50 }}
                      value={landmark}
                      placeholder="Eg: Eiffel Tower"
                      onChange={(e) => {
                        set_landmark(e.target.value);
                        setErrorMessage('');
                      }}
                    />
                  </div>
                </Grid> */}
                {/* <Grid sm={12} xs={12} md={12} lg={12} xl={12}>
                  <div className="_verification_row">
                    <label>Validity Period</label>
                    <input
                      className="_verification_fields"
                      value={promotion_period}
                      type="date"
                      style={{ height: 50 }}
                      onChange={(e) => {
                        set_promotion_period(e.target.value);
                        setErrorMessage('');
                      }}
                    />
                  </div>
                </Grid> */}
                <Grid sm={12} xs={12} md={12} lg={12} xl={12}>
                  <div className="_verification_row">
                    <label>*Start Date</label>
                    <input
                      className="_verification_fields"
                      value={promotion_satrt_date}
                      type="date"
                      style={{ height: 50 }}
                      onChange={(e) => {
                        set_promotion_satrt_date(e.target.value);
                        setErrorMessage('');
                      }}
                    />
                  </div>
                </Grid>
                <Grid sm={12} xs={12} md={12} lg={12} xl={12}>
                  <div className="_verification_row">
                    <label>*End Date</label>
                    <input
                      className="_verification_fields"
                      value={promotion_end_date}
                      type="date"
                      style={{ height: 50 }}
                      onChange={(e) => {
                        set_promotion_end_date(e.target.value);
                        setErrorMessage('');
                      }}
                    />
                  </div>
                </Grid>
                <Grid sm={12} xs={12} md={12} lg={12} xl={12}>
                  <p style={{ color: 'red', fontSize: 12 }}>{errorMessage}</p>
                </Grid>
                <Grid sm={12} xs={12} md={12} lg={12} xl={12}>
                  <div className="_verification_row">
                    <label>*Image</label>
                    {/* <label htmlFor="_update_promo"> */}
                    <label
                      htmlFor="_update_promo"
                      className="_verification_fields"
                      style={{ border: '1px solid', display: 'block', textAlign: 'center', height: 60 }}
                    >
                      <AiOutlineFileAdd size={16} color="#972B34" />
                      <div style={{ fontSize: 12 }}>upload image</div>
                    </label>
                    {promotion_img !== '' && (
                      <img src={promotion_img} style={{ height: 150, width: '100%', marginTop: 10 }} />
                    )}
                    {/* </label> */}
                    <input
                      className="_verification_fields"
                      type="file"
                      id="_update_promo"
                      style={{ height: 50, display: 'none' }}
                      onChange={(e) => upload_image(e.target.files[0], 'save')}
                    />
                  </div>
                </Grid>

                <Grid sm={12} xs={12} md={12} lg={12} xl={12}>
                  <div className="_verification_row">
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        cursor: 'pointer',
                      }}
                      onClick={() => savePromotion()}
                    >
                      <button
                        style={{ width: 163 }}
                        className="_btn outline_btn_modal"
                        onClick={() => setviewDetails(false)}
                      >
                        Cancel
                      </button>
                      <button className="_btn" style={{ width: 163 }}>
                        Save
                      </button>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>

            {/* <Grid sm={12} xs={12} md={6} lg={6} xl={6}>
              <div className="_document_view">
                {loadkyc ? (
                  <div className="_loading_section">
                    {' '}
                    <CircularProgress color="secondary" />
                  </div>
                ) : (
                  <>
                    <label>Attached Documents</label>
                    <div className="_documents_row">
                      {kyc.length !== 0 ? (
                        <img src={kyc[0].document.path} style={{ border: '1px solid', height: 200, width: '100%' }} />
                      ) : (
                        <p style={{ textAlign: 'center' }}>Not Found</p>
                      )}
                    </div>
                    <br />

                   
                  </>
                )}
              </div>
              <br />

              {kyc.length !== 0 && (
                <div
                  style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}
                >
                  <button
                    className="_btn"
                    style={{ width: 85, margin: 5, backgroundColor: '#F43131', border: 'none' }}
                    onClick={() => rejectDoc()}
                  >
                    {selected.data?.verificationStatus === 'REJECTED' ? 'Rejected' : 'Reject'}
                  </button>
                  {selected.data?.verificationStatus === 'VERIFIED' && (
                    <button
                      className="_btn"
                      style={{ width: 85, marign: 5, backgroundColor: '#4FCC3B', border: 'none' }}
                      disabled
                    >
                      Accepted
                    </button>
                  )}

                  {selected.data?.verificationStatus === 'PENDING' && (
                    <button
                      className="_btn"
                      style={{ width: 85, marign: 5, backgroundColor: '#4FCC3B', border: 'none' }}
                      onClick={() => acceptDoc()}
                    >
                      Accept
                    </button>
                  )}
                </div>
              )}
            </Grid> */}
          </Grid>
        </Container>
      )}
      <Snackbar open={snackOpen} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={messageType} sx={{ width: '100%' }} style={{ color: '#fff' }}>
          {message}
        </Alert>
      </Snackbar>
    </Page>
  );
}
