import { Navigate, Route, useRoutes } from 'react-router-dom';
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
import Login from './pages/Login';
import NotFound from './pages/Page404';
// import Users from './pages/Merchant';
import Categories from './pages/Categories';
import Deal from './pages/Deal';
import Interest from './pages/Interest';
import Merchant from './pages/Merchant';
import Users from './pages/Users';
import Card from './pages/Card';
import Dashboard from './pages/Dashboard';
import Banner from './pages/Banner';
import Promotion from './pages/Promotion';
import Landmark from './pages/Landmark'

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/admin',
      element: <DashboardLayout />,
      children: [
        { path: 'merchant', element: <Merchant /> },
        { path: 'category', element: <Categories /> },
        { path: 'interest', element: <Interest /> },
        { path: 'card', element: <Card /> },
        { path: 'deal', element: <Deal /> },
        { path: 'promotion', element: <Promotion /> },
        { path: 'banner', element: <Banner /> },
        { path: 'users', element: <Users /> },
        { path: 'dashboard', element: <Dashboard /> },
        { path: 'landmark', element: <Landmark /> },


        // { path: 'transactions', element: <Transactions /> },
        // { path: 'logout', element: <Login /> },
      ],
    },
    {
      path: 'login',
      element: <Login />,
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Navigate to="/login" /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);
}
