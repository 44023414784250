import { useRef, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { alpha, styled } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton, Link } from '@mui/material';
// components
import MenuPopover from '../../components/MenuPopover';
// mocks_
import account from '../../_mock/account';
import { indexOf } from 'lodash';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Home',
    icon: 'eva:home-fill',
    linkTo: '/',
  },
  {
    label: 'Profile',
    icon: 'eva:person-fill',
    linkTo: '#',
  },
  {
    label: 'Settings',
    icon: 'eva:settings-2-fill',
    linkTo: '#',
  },
];

// ----------------------------------------------------------------------
const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
}));
export default function AccountPopover() {
  const anchorRef = useRef(null);
  const navigate = useNavigate();

  const [adminUserData, setAdminUserData] = useState(JSON.parse(localStorage.getItem('adminUserData')));

  console.log('===========>>>>>>', adminUserData);

  const handleClose = () => {
    localStorage.removeItem('currentuser');
    setOpen(null);
    navigate('/login', { replace: true });
  };

  return (
    <>
      <Box
        ref={anchorRef}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              borderRadius: '50%',
            },
          }),
        }}
      >
        <Link underline="none" component={RouterLink} to="#">
          <AccountStyle style={{ backgroundColor: 'white', height: 50 }}>
            <img
              src={require('../../images/useravatar.png')}
              alt="photoURL"
              style={{ borderRadius: 11, width: 35, height: 35 }}
            />
            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                {/* Hello, {adminUserData.email.slice(0, adminUserData.email.indexOf('@'))} */}
                Hello, {adminUserData.name}
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.primary' }}>
                {adminUserData.role}
                {/* {adminUserData.email} */}
              </Typography>
            </Box>
          </AccountStyle>
        </Link>
      </Box>
    </>
  );
}
