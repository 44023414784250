import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Toolbar, Grid } from '@mui/material';
import Page from '../components/Page';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './styles/login.css';
import { HiOutlineMail } from 'react-icons/hi';
import { VscLock } from 'react-icons/vsc';
import { BASE_URL } from 'src/config/api';

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 72;
// ----------------------------------------------------------------------
const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

export default function Login() {
  const navigate = useNavigate();
  const [email, setemail] = useState('ashm18@gmail.com');
  const [password, setpassword] = useState('123456');
  const [err, setErr] = useState('');

  const signIn = () => {
    axios({
      method: 'post',
      url: `${BASE_URL}/users/admin/login`,
      data: {
        email: email,
        password: password,
      },
    })
      .then((res) => {
        console.log(res);
        localStorage.setItem('adminUserData', JSON.stringify(res.data));
        // navigate('/admin/users');
        window.location.assign('/admin/merchant');
        setemail('');
        setpassword('');
      })
      .catch((err) => {
        console.log(err.response);
        // setErr(err.response.data.meta.message);
      });
    // if (email === 'ashm18@gmail.com' && password === 'admin@123') {
    //   navigate('/admin/merchant');
    // } else {
    //   setErr('Invalid email or password');
    // }
  };
  return (
    <div className="_login_container">
      <Page title="Login">
        <RootStyle>
          <Grid container justifyContent="center">
            {/* <Grid sm={12} xs={12} md={6} lg={6} xl={6}>
              <img src={require('../images/Crypto Illustration.png')} alt="Login" className="_login_left_logo" />
            </Grid> */}
            <Grid sm={12} xs={12} md={4} lg={4} xl={4}>
              <ContentStyle>
                <div className="_login_form">
                  <img src={require('../images/Logo.png')} height={148} width={195} style={{ margin: 'auto' }} />

                  <div>
                    <div className="_auth_fields_label">Email</div>
                    <div className="_login_row">
                      <HiOutlineMail className="_auth_fields_icons" />
                      <input
                        type={'email'}
                        placeholder="Enter Your Email"
                        value={email}
                        className="_auth_fields"
                        onChange={(e) => {
                          setemail(e.target.value), setErr('');
                        }}
                      />
                    </div>
                  </div>

                  <div>
                    <div className="_auth_fields_label">Password</div>
                    <div className="_login_row">
                      <VscLock className="_auth_fields_icons" />
                      <input
                        type={'password'}
                        value={password}
                        onChange={(e) => {
                          setpassword(e.target.value), setErr('');
                        }}
                        placeholder="Enter Your Password"
                        className="_auth_fields"
                      />
                    </div>
                  </div>
                  <p style={{ color: 'red', fontSize: 10 }}>{err}</p>

                  <br />

                  <button className="_btn" onClick={() => signIn()}>
                    Login
                  </button>
                </div>
              </ContentStyle>
            </Grid>
            
          </Grid>
        </RootStyle>
      </Page>
    </div>
  );
}
