import * as React from "react"

const UsersIcons = (props) => (
  <svg
    width={21}
    height={21}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.543 14.25a3.333 3.333 0 1 1 0-6.667 3.333 3.333 0 0 1 0 6.666Zm0-5a1.667 1.667 0 1 0 0 3.333 1.667 1.667 0 0 0 0-3.334Zm5 10.832a5 5 0 0 0-10 0 .833.833 0 1 0 1.667 0 3.333 3.333 0 0 1 6.667 0 .833.833 0 1 0 1.666 0Zm0-12.5a3.333 3.333 0 1 1 0-6.666 3.333 3.333 0 0 1 0 6.666Zm0-5a1.666 1.666 0 1 0 0 3.333 1.666 1.666 0 0 0 0-3.333Zm5 10.834a5.005 5.005 0 0 0-5-5 .833.833 0 1 0 0 1.666 3.333 3.333 0 0 1 3.334 3.334.833.833 0 0 0 1.666 0Zm-15-5.834a3.333 3.333 0 1 1 0-6.666 3.333 3.333 0 0 1 0 6.666Zm0-5a1.667 1.667 0 1 0 0 3.334 1.667 1.667 0 0 0 0-3.334ZM2.21 13.416a3.333 3.333 0 0 1 3.333-3.334.833.833 0 1 0 0-1.666 5.005 5.005 0 0 0-5 5 .833.833 0 0 0 1.667 0Z"
      fill= {props.fill ?  props.fill:"#5A5A5C"}
    />
  </svg>
)

export default UsersIcons
