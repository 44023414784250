import * as React from "react"

const SearchIcon = (props) => (
  <svg
    width={18}
    height={18}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask
      id="a"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={18}
      height={18}
    >
      <path
        d="m16.777 16.583-3.61-3.487 3.61 3.487Zm-1.61-8.148c0 1.75-.72 3.427-2.004 4.664a6.974 6.974 0 0 1-4.838 1.932A6.974 6.974 0 0 1 3.487 13.1a6.479 6.479 0 0 1-2.004-4.664c0-1.75.721-3.428 2.004-4.665a6.974 6.974 0 0 1 4.838-1.932c1.815 0 3.555.695 4.838 1.932a6.478 6.478 0 0 1 2.004 4.665v0Z"
        stroke="#000"
        strokeWidth={1.693}
        strokeLinecap="round"
      />
    </mask>
    <g mask="url(#a)">
      <path
        d="M-.53-.525h19.32v19.472H-.53V-.525Z"
        fill="#5A5A5C"
        fillOpacity={0.4}
      />
    </g>
  </svg>
)

export default SearchIcon
