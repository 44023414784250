import React, { useRef, useEffect, useMemo } from 'react';
import JoditEditor from 'jodit-react';

const JoditComponent = ({ initialContent, onChange }) => {
  const editorRef = useRef(null);

  const config = {};

  return (
      <JoditEditor
        ref={editorRef}
        config={config}
        onBlur={(content) => onChange(content)} // Call handleContentChange on editor change
        value={initialContent}
        tabIndex={1} // Set a tabIndex to ensure the editor can receive focus
      />
  );
};

// Wrap the component with React.memo
export default React.memo(JoditComponent);
