import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { NavLink as RouterLink, matchPath, useLocation } from 'react-router-dom';

const bull = (
  <Box component="span" sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}>
    •
  </Box>
);

export default function DashboardCard({ data }) {
  return (
    <Card style={{ margin: 10 }}>
      <CardContent>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography sx={{ fontSize: 16 }} component="div" color="text.secondary" gutterBottom>
            {data.title}
          </Typography>
          <Typography variant="h5" component="div">
            {data.total}
          </Typography>
        </div>
      </CardContent>
      <CardActions>
        <RouterLink to={data.path}>
        <Button variant="outlined" size="small">
          View Details
        </Button>
        </RouterLink>
      </CardActions>
    </Card>
  );
}
