import React, { useState, useEffect } from 'react';
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  TableContainer,
  TablePagination,
  Grid,
} from '@mui/material';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead } from '../sections/@dashboard/user';
import { SearchIcon, CloseIcon } from '../images/svg';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CircularProgress from '@mui/material/CircularProgress';
import moment from 'moment';
import './styles/merchant.css';
import axios from 'axios';
import { IoIosAdd } from 'react-icons/io';
import { BASE_URL, UPLOAD_URL } from 'src/config/api';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));
// ----------------------------------------------------------------------
const TABLE_HEAD = [
  { id: 'merchant name', label: 'Merchant Name', alignRight: false },
  { id: 'Merchant Management', label: 'Merchant Management', alignRight: true },
];
// ----------------------------------------------------------------------

export default function Merchant() {
  const [page, setPage] = useState(0);
  const [selected, setSelected] = useState(0);
  const [open, setOpen] = React.useState(false);
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [usersList, setusersList] = useState([]);
  const [viewDetail, setviewDetails] = useState(false);
  const [selectedUser, setselectedUser] = useState({});
  const [loading, setloading] = useState(false);
  const [loadkyc, setloadkyc] = useState(false);
  const [reason, setreason] = useState('');
  const [kyc, setkyc] = useState([]);
  const [searchText, setsearchText] = useState('');
  const [start_date, setstart_date] = useState(moment(new Date()).format('yyyy-MM-DD'));
  const [end_date, setend_date] = useState(moment(new Date()).format('yyyy-MM-DD'));
  const [adminUserData, setAdminUserData] = useState(JSON.parse(localStorage.getItem('adminUserData')));
  const [errorMessage, setErrorMessage] = useState('');
  const [messageColor, setMessageColor] = useState('red');
  const [viewWallet, setviewWallet] = useState(false);
  const [showadd, setshowadd] = useState(false);
  const [name, set_name] = useState('');
  const [contactInfo, set_contactInfo] = useState('');
  const [location, set_location] = useState('');
  const [description, set_description] = useState('');
  const [merchant_img, set_merchant_img] = useState('');

  const fetch_merchant = async (search) => {
    setloading(true);
    axios({
      method: 'get',
      url: `${BASE_URL}/Merchant`,
      // params: {
      //   Authorization: adminUserData?.token,
      // },
    })
      .then((res) => {
        setloading(false);
        setusersList(res.data);
        console.log('==========>>users=====>>>', res.data);
      })
      .catch((err) => {
        setloading(false);
      });
  };

  useEffect(() => {
    (async () => {
      // await setAdminUserData(JSON.parse(localStorage.getItem('adminUserData')));
      console.log('adminUserData', adminUserData);
      fetch_merchant();
    })();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const statusHandleChange = async () => {
    let axiosConfig = {
      Authorization: adminUserData?.token,
      userId: selected.data.id,
      status: 'inactive',
      statusReason: reason,
    };

    await axios
      .put(`${BASE_URL}profile/status`, axiosConfig)
      .then((res) => {
        if (usersList[selected.key].status === 'active') {
          usersList[selected.key].status = 'inactive';
        } else {
          usersList[selected.key].status = 'active';
        }
        setusersList([...usersList]);
        console.log('==========>>usersList=====>>>', usersList);
        setOpen(false);
      })
      .catch((err) => {
        console.log(err.data);
      });
  };

  const getUserKfc = async (userId) => {
    setloadkyc(true);
    let axiosConfig = {
      Authorization: adminUserData?.token,
    };
    await axios
      .get(`${BASE_URL}kyc/user/${userId}`, axiosConfig)
      .then((res) => {
        setkyc(res.data.data);
        setloadkyc(false);
      })
      .catch((err) => {
        setloadkyc(false);
      });
  };

  const saveDetails = async () => {
    if (selected.data.name === '') {
      setErrorMessage('Kinly enter Merchant Name');
      setMessageColor('red');
    } else if (!selected.data.contact || selected.data.contact === '') {
      setErrorMessage('Kinly enter Merchant Contact Information');
      setMessageColor('red');
    } else if (!selected.data?.location || selected.data?.location === '') {
      setErrorMessage('Kinly enter Merchant Location');
      setMessageColor('red');
    } else if (!selected.data?.description || selected.data?.description === '') {
      setErrorMessage('Kinly enter Merchant Description');
      setMessageColor('red');
    } else {
      // alert('successfully Saved');
      // setviewDetails(false);
      // setErrorMessage('Successfully Saved');
      // setMessageColor('green');
      // setTimeout(() => {
      //   setErrorMessage('');
      //   setMessageColor('red');
      // }, 3000);
      let axiosConfig = {
        Authorization: adminUserData?.token,
        name: selected.data.name,
        description: selected.data.description,
        contact: selected.data.contact,
        location: selected.data.location,
        photo: selected.data.photo,
        // image:"kjhk"
      };
      await axios
        .patch(`${BASE_URL}/Merchant/${selected.data.id}`, axiosConfig)
        .then((res) => {
          fetch_merchant();
          setviewDetails(false);
          // setSnackOpen(true);
          setMessage('Merchant updated successfully');
          setMessageType('success');
        })
        .catch((err) => {
          setErrorMessage(err?.response?.data);
        });
    }
  };

  const delete_merchant = async () => {
    var _confirm = confirm("Are you sure you want to delete or cancel to go back")
if(_confirm){
    let axiosConfig = {
      Authorization: adminUserData?.token,
    };
    await axios
      .delete(`${BASE_URL}/Merchant/${selected.data.id}`, axiosConfig)
      .then((res) => {
        console.log('working');
        fetch_merchant();
        setviewDetails(false);
        // setSnackOpen(true);
        setMessage('Merchant deleted successfully');
        setMessageType('success');
      })
      .catch((err) => {
        console.log(err);
      });
    }
  };

  const save_merchant = async () => {
    if (name === '') {
      setErrorMessage('Kinly enter Merchant Name');
      setMessageColor('red');
    } else if (contactInfo === '') {
      setErrorMessage('Kinly enter Merchant Contact Information');
      setMessageColor('red');
    } else if (location === '') {
      setErrorMessage('Kinly enter Merchant Location');
      setMessageColor('red');
    } else if (description === '') {
      setErrorMessage('Kinly enter Merchant Description');
      setMessageColor('red');
    } else {
      // alert('successfully Saved');
      // setviewDetails(false);
      // setErrorMessage('Successfully Saved');
      // setMessageColor('green');
      // setTimeout(() => {
      //   setErrorMessage('');
      //   setMessageColor('red');
      // }, 3000);
      let axiosConfig = {
        Authorization: adminUserData?.token,
      };
      await axios
        .post(
          `${BASE_URL}/Merchant`,
          {
            name: name,
            description: description,
            contact: contactInfo,
            location: location,
            photo: merchant_img,
          },
          axiosConfig
        )
        .then((res) => {
          fetch_merchant();
          setviewDetails(false);
          setshowadd(false);
          set_name('');
          set_description('');
          set_location('');
          setErrorMessage('');
          merchant_img('');
          setMessage('Merchant saved successfully');
          setMessageType('success');
        })
        .catch((err) => {
          console.log(err);
          setErrorMessage(err.data);
        });
    }
  };

  const upload_image = (_file, type) => {
    console.log('-------_file-------->', _file);
    var formData = new FormData();
    formData.append('file', _file);
    formData.append('upload_preset', 'ml_default');
    formData.append('api_key', '767293436452984');

    axios({
      method: 'post',
      url: UPLOAD_URL,
      data: formData,
    })
      .then(function (response) {
        if (type === 'edit') {
          selected.data.photo = response.data.url;
          setSelected({ ...selected });
        } else {
          set_merchant_img(response.data.url);
          setErrorMessage('');
        }
        console.log('////response/////', response.data.url);
      })
      .catch(function (error) {
        console.log('////error/////', error);
      });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - usersList.length) : 0;
  // const filteredUsers = usersList;

  console.log(start_date);

  const filteredUsers = usersList.filter((users) => {
    return users.name.toLowerCase().includes(searchText.toLowerCase());
  });
  const isUserNotFound = filteredUsers.length === 0;
  return (
    <Page title="Merchant">
      {!viewDetail ? (
        <Container>
          <Grid container justifyContent={'space-between'}>
            <Grid sm={12} xs={12} md={4} lg={4} xl={4}>
              <div className="_login_row" style={{ marginBottom: 4 }}>
                <SearchIcon className="_auth_fields_icons" />
                <input
                  type={'text'}
                  placeholder="Search merchant"
                  className="_auth_fields"
                  value={searchText}
                  onChange={(e) => setsearchText(e.target.value)}
                />
              </div>
            </Grid>
            <Grid sm={12} xs={12} md={3} lg={2} xl={2}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginBottom: 5 }}>
                <button
                  className="_btn"
                  style={{ width: '100%', alignSelf: 'flex-end' }}
                  // onClick={() => exportData()}
                  onClick={() => {
                    setviewDetails(true);
                    setshowadd(true);
                  }}
                >
                  <IoIosAdd style={{ color: 'white' }} size={25} /> Add Merchant
                </button>
              </div>
            </Grid>
          </Grid>
          <br />
          <Card style={{ borderRadius: 0 }}>
            <Scrollbar>
              <TableContainer>
                {loading ? (
                  <div className="_loading_section">
                    {' '}
                    <CircularProgress color="secondary" />
                  </div>
                ) : (
                  <Table>
                    <UserListHead headLabel={TABLE_HEAD} rowCount={usersList.length} />
                    <TableBody>
                      {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, key) => {
                        const { id } = row;
                        return (
                          <TableRow hover key={id} style={{ fontSize: 14, fontWeight: 400 }}>
                            <TableCell scope="row">
                              <Stack
                                direction="row"
                                alignItems="center"
                                spacing={2}
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                  setviewWallet(true), setselectedUser(row);
                                }}
                              >
                                <div className="_profile_view">
                                  {row.photo ? (
                                    <img src={row.photo} className="_merchant_logo" />
                                  ) : (
                                    <img src={require('../images/useravatar.png')} className="_merchant_logo" />
                                  )}
                                </div>

                                <div>{row.name ? row.name : '--'}</div>
                              </Stack>
                            </TableCell>

                            <TableCell align="right">
                              <div
                                onClick={() => {
                                  getUserKfc(row.id), setSelected({ data: row, key: key }), setviewDetails(true);
                                  setshowadd(false);
                                }}
                              >
                                <button className="_view_btn" style={{ width: 100 }}>
                                  Details
                                </button>
                              </div>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                    {isUserNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filterName} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                )}
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={usersList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
          {/* CHANGE STATUS MODAL */}

          <BootstrapDialog aria-labelledby="customized-dialog-title" open={open} maxWidth="xs" fullWidth={true}>
            <div className="_user_modal_header">
              <div>Reason</div>
              <span onClick={() => setOpen(false)}>
                <CloseIcon />
              </span>
            </div>

            <DialogContent dividers>
              <div className="_user_modal_body">
                <div className="_user_modal_body_title">Enter Reason</div>
                <textarea
                  placeholder="Type here.."
                  className="_auth_fields _user_modal_text_area"
                  value={reason}
                  onChange={(e) => setreason(e.target.value)}
                ></textarea>
              </div>
            </DialogContent>
            <DialogActions>
              <div className="_user_modal_footer">
                <button className="_btn outline_btn_modal" onClick={() => setOpen(false)}>
                  Cancel
                </button>
                <button className="_btn" onClick={() => statusHandleChange()} disabled={reason === '' ? true : false}>
                  Save
                </button>
              </div>
            </DialogActions>
          </BootstrapDialog>
        </Container>
      ) : showadd ? (
        <Container>
          <div className="_user_details_title">
            {' '}
            <span>Merchant</span>/Add
          </div>
          <Grid container>
            <Grid sm={12} xs={12} md={3} lg={3} xl={3}>
              <div className="_document_view">
                {loadkyc ? (
                  <div className="_loading_section">
                    {' '}
                    <CircularProgress color="secondary" />
                  </div>
                ) : (
                  <>
                    <label>Merchant Logo</label>
                    <br />
                    <br />

                    <div
                      className="_documents_row"
                      style={{ justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}
                    >
                      {merchant_img === '' ? (
                        <img
                          src={require('../images/useravatar.png')}
                          style={{
                            height: 150,
                            width: 150,
                            borderRadius: 100,
                            overflow: 'hidden',
                            alignSelf: 'center',
                          }}
                        />
                      ) : (
                        <img
                          src={merchant_img}
                          style={{
                            height: 150,
                            width: 150,
                            borderRadius: 100,
                            overflow: 'hidden',
                            alignSelf: 'center',
                          }}
                        />
                      )}

                      <br />
                      <br />

                      <input
                        className="_verification_fields"
                        // value={card_img}
                        type="file"
                        id="_update_promo"
                        style={{ height: 50, display: 'none' }}
                        onChange={(e) => upload_image(e.target.files[0], 'upload')}
                      />
                      <label className="_btn" style={{ width: 143, fontWeight: '300' }} htmlFor="_update_promo">
                        upload logo
                      </label>
                    </div>
                  </>
                )}
                <br />
              </div>
            </Grid>
            <Grid sm={12} xs={12} md={9} lg={9} xl={9}>
              <Grid container>
                <Grid sm={12} xs={12} md={12} lg={12} xl={12}>
                  <div className="_verification_row">
                    <label>Merchant Name</label>
                    <input
                      className="_verification_fields"
                      value={name}
                      onChange={(e) => {
                        set_name(e.target.value);
                        setErrorMessage('');
                      }}
                    />
                  </div>
                </Grid>
                <Grid sm={12} xs={12} md={12} lg={12} xl={12}>
                  <div className="_verification_row">
                    <label>Contact Information</label>
                    <input
                      className="_verification_fields"
                      value={contactInfo}
                      onChange={(e) => {
                        set_contactInfo(e.target.value);
                        setErrorMessage('');
                      }}
                    />
                  </div>
                </Grid>

                <Grid sm={12} xs={12} md={12} lg={12} xl={12}>
                  <div className="_verification_row">
                    <label>Location</label>
                    <input
                      className="_verification_fields"
                      value={location}
                      onChange={(e) => {
                        set_location(e.target.value);
                        setErrorMessage('');
                      }}
                    />
                  </div>
                </Grid>

                <Grid sm={12} xs={12} md={12} lg={12} xl={12}>
                  <div className="_verification_row">
                    <label>Description</label>
                    <input
                      className="_verification_fields"
                      style={{ height: 80 }}
                      value={description}
                      onChange={(e) => {
                        set_description(e.target.value);
                        setErrorMessage('');
                      }}
                    />
                  </div>
                </Grid>
                <Grid sm={12} xs={12} md={12} lg={12} xl={12}>
                  <div className="_verification_row">
                    <p style={{ color: messageColor, fontSize: 12 }}>{errorMessage}</p>
                  </div>
                </Grid>
              </Grid>
              <br />
              <div className="_user_modal_footer" style={{ justifyContent: 'flex-end' }}>
                <button style={{ width: 163 }} className="_btn outline_btn_modal" onClick={() => setviewDetails(false)}>
                  Cancel
                </button>
                <button className="_btn" onClick={() => save_merchant()}>
                  Save
                </button>
              </div>
            </Grid>
          </Grid>
        </Container>
      ) : (
        <Container>
          <div className="_user_details_title">
            {' '}
            <span>Merchant</span>/Management
          </div>
          <Grid container>
            <Grid sm={12} xs={12} md={3} lg={3} xl={3}>
              <div className="_document_view">
                {loadkyc ? (
                  <div className="_loading_section">
                    {' '}
                    <CircularProgress color="secondary" />
                  </div>
                ) : (
                  <>
                    <label>Merchant Logo</label>
                    <br />
                    <br />

                    <div
                      className="_documents_row"
                      style={{ justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}
                    >
                      {/* <img
                        src={require('../images/useravatar.png')}
                        style={{ height: 150, width: 150, borderRadius: 100, overflow: 'hidden', alignSelf: 'center' }}
                      /> */}
                      {!selected.data.photo ? (
                        <img
                          src={require('../images/useravatar.png')}
                          style={{
                            height: 150,
                            width: 150,
                            borderRadius: 100,
                            overflow: 'hidden',
                            alignSelf: 'center',
                          }}
                        />
                      ) : (
                        <img
                          src={selected.data.photo}
                          style={{
                            height: 150,
                            width: 150,
                            borderRadius: 100,
                            overflow: 'hidden',
                            alignSelf: 'center',
                          }}
                        />
                      )}

                      <br />
                      <br />

                      <input
                        className="_verification_fields"
                        type="file"
                        id="_update_logo"
                        style={{ height: 50, display: 'none' }}
                        onChange={(e) => upload_image(e.target.files[0], 'edit')}
                      />

                      <label className="_btn" style={{ width: 143, fontWeight: '300' }} htmlFor="_update_logo">
                        upload logo
                      </label>
                    </div>
                  </>
                )}
                <br />
              </div>
            </Grid>
            <Grid sm={12} xs={12} md={9} lg={9} xl={9}>
              <Grid container>
                <Grid sm={12} xs={12} md={12} lg={12} xl={12}>
                  <div className="_verification_row">
                    <label>Merchant Name</label>
                    <input
                      className="_verification_fields"
                      value={selected.data?.name}
                      onChange={(e) => {
                        selected.data.name = e.target.value;
                        setSelected({ ...selected });
                        setErrorMessage('');
                      }}
                    />
                  </div>
                </Grid>
                <Grid sm={12} xs={12} md={12} lg={12} xl={12}>
                  <div className="_verification_row">
                    <label>Contact Information</label>
                    <input
                      className="_verification_fields"
                      value={selected.data?.contact}
                      onChange={(e) => {
                        selected.data.contact = e.target.value;
                        setSelected({ ...selected });
                        setErrorMessage('');
                      }}
                    />
                  </div>
                </Grid>

                <Grid sm={12} xs={12} md={12} lg={12} xl={12}>
                  <div className="_verification_row">
                    <label>Location</label>
                    <input
                      className="_verification_fields"
                      value={selected.data?.location}
                      onChange={(e) => {
                        selected.data.location = e.target.value;
                        setSelected({ ...selected });
                        setErrorMessage('');
                      }}
                    />
                  </div>
                </Grid>

                <Grid sm={12} xs={12} md={12} lg={12} xl={12}>
                  <div className="_verification_row">
                    <label>Description</label>
                    <input
                      className="_verification_fields"
                      style={{ height: 80 }}
                      value={selected.data?.description}
                      onChange={(e) => {
                        selected.data.description = e.target.value;
                        setSelected({ ...selected });
                        setErrorMessage('');
                      }}
                    />
                  </div>
                </Grid>
                <Grid sm={12} xs={12} md={12} lg={12} xl={12}>
                  <div className="_verification_row">
                    <p style={{ color: messageColor, fontSize: 12 }}>{errorMessage}</p>
                  </div>
                </Grid>
              </Grid>
              <br />
              <div className="_user_modal_footer" style={{ justifyContent: 'flex-end' }}>
                <button style={{ width: 163 }} className="_btn outline_btn_modal" onClick={() => setviewDetails(false)}>
                  Cancel
                </button>
                {adminUserData.role === 'admin' ? (
                  <button className="_btn" style={{ backgroundColor: 'red' }} onClick={() => delete_merchant()}>
                    Delete
                  </button>
                ) : null}
                <button className="_btn" onClick={() => saveDetails()}>
                  Save
                </button>
              </div>
            </Grid>
          </Grid>
        </Container>
      )}
    </Page>
  );
}
