import * as React from "react"

const AppControlsIcon = (props) => (
  <svg
    width={21}
    height={21}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.585.883v2.28a3.107 3.107 0 0 0 0 5.996v10.057a.833.833 0 0 0 1.666 0V9.16a3.107 3.107 0 0 0 0-5.996V.883a.833.833 0 1 0-1.666 0Zm2.291 5.278a1.459 1.459 0 1 1-2.917 0 1.459 1.459 0 0 1 2.917 0ZM11.377 19.216v-2.28a3.106 3.106 0 0 0 0-5.995V.883a.833.833 0 1 0-1.666 0v10.059a3.106 3.106 0 0 0 0 5.994v2.28a.834.834 0 0 0 1.666 0ZM9.086 13.94a1.459 1.459 0 1 1 2.917 0 1.459 1.459 0 0 1-2.917 0ZM4.502 19.216V9.16a3.107 3.107 0 0 0 0-5.996V.883a.833.833 0 1 0-1.667 0v2.28a3.107 3.107 0 0 0 0 5.996v10.057a.834.834 0 0 0 1.667 0ZM2.21 6.161a1.458 1.458 0 1 1 2.917 0 1.458 1.458 0 0 1-2.917 0Z"
      fill= {props.fill ?  props.fill:"#5A5A5C"}
    />
  </svg>
)

export default AppControlsIcon
