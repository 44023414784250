// component
import Iconify from '../../components/Iconify';
// ----------------------------------------------------------------------
const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;
import { GrGroup } from 'react-icons/gr';
import { VscSettings } from 'react-icons/vsc';

const adminUserData = JSON.parse(localStorage.getItem('adminUserData'));

const navConfig = [
  {
    title: 'Dashboard ',
    path: '/admin/dashboard',
  },
  {
    title: 'Merchant ',
    path: '/admin/merchant',
  },

  {
    title: 'Category',
    path: '/admin/category',
  },
  {
    title: 'Interest ',
    path: '/admin/interest',
  },
  {
    title: 'Card ',
    path: '/admin/card',
  },
  {
    title: 'Deal ',
    path: '/admin/deal',
  },
  {
    title: 'Landmark ',
    path: '/admin/landmark',
  },
  {
    title: 'Promotion ',
    path: '/admin/promotion',
  },
  {
    title: 'Banner ',
    path: '/admin/banner',
  },
  {
    title: 'Users',
    path: '/admin/users',
  },

  {
    title: 'Logout',
    path: '/admin/logout',
  },
];
var removeValFrom = [2, 3, 6, 7];
if (adminUserData && adminUserData.role !== 'admin')
  for (var i = removeValFrom.length - 1; i >= 0; i--) navConfig.splice(removeValFrom[i], 1);

export default navConfig;
