import React, { useState, useEffect } from 'react';
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  TableContainer,
  TablePagination,
  Grid,
} from '@mui/material';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead } from '../sections/@dashboard/user';
import { SearchIcon, CloseIcon, EyeIcon, DeleteIcon, EditIcon } from '../images/svg';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import CircularProgress from '@mui/material/CircularProgress';
import moment from 'moment';
import './styles/merchant.css';
import { BASE_URL, UPLOAD_URL } from 'src/config/api';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
// import UserWallet from 'src/components/userWallet';
import { IoIosAdd } from 'react-icons/io';
import { AiOutlineFileAdd } from 'react-icons/ai';
import { BiCategory } from 'react-icons/bi';
import theme from '../theme/index';
import './styles/category.css';
import { TagsInput } from 'react-tag-input-component';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import ImageUpload from 'src/components/FileUpload';
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));
// ----------------------------------------------------------------------
const TABLE_HEAD = [
  { id: 'Category', label: 'Category', alignRight: false },
  { id: 'Description', label: 'Description', alignRight: false },
  { id: 'Tags', label: 'Tags', alignRight: false },
  { id: 'Action', label: 'Action', alignRight: false },
];
// ----------------------------------------------------------------------

export default function Places() {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [selected, setSelected] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [usersList, setusersList] = useState([]);
  const [viewDetail, setviewDetails] = useState(false);
  const [loading, setloading] = useState(false);
  const [reason, setreason] = useState('');
  const [searchText, setsearchText] = useState('');
  const [start_date, setstart_date] = useState(moment(new Date()).format('yyyy-MM-DD'));
  const [end_date, setend_date] = useState(moment(new Date()).format('yyyy-MM-DD'));
  const [adminUserData, setAdminUserData] = useState(JSON.parse(localStorage.getItem('adminUserData')));
  const [tags, settags] = useState(['papaya']);
  const [cat_title, setcat_title] = useState('');
  const [cat_desc, setcat_desc] = useState('');
  const [category_img, set_category_img] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('success');
  const [snackOpen, setSnackOpen] = useState(false);
  const fetchUsers = async (search) => {
    setloading(true);
    axios({
      method: 'get',
      url: `${BASE_URL}/category`,
      withCredentails: false,
      params: {
        Authorization: adminUserData?.token,
      },
    })
      .then((res) => {
        setloading(false);
        setusersList([...res.data]);
      })
      .catch((err) => {
        setloading(false);
      });
  };

  useEffect(() => {
    (async () => {
      fetchUsers();
    })();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const updateCategory = async () => {
    if (selected.name === '') {
      setErrorMessage('Please enter the Category Name');
    } else if (selected.description === '') {
      setErrorMessage('Please enter the Category Description');
    } else if (selected.tags.length === 0) {
      setErrorMessage('Please Select the Category Tags');
    } else if (selected.image === '') {
      setErrorMessage('Please Select the Category Image');
    } else {
      // console.log('=============', selected);
      let axiosConfig = {
        name: selected.name,
        description: selected.description,
        image: selected.image,
        tags: selected.tags,
      };
      await axios
        .patch(`${BASE_URL}/category/${selected.id}`, axiosConfig)
        .then((res) => {
          fetchUsers();
          console.log('===success==>', res.response);
          setOpen(false);
          setErrorMessage('');
          setSnackOpen(true);
          setMessage('Category updated successfully');
          setMessageType('success');
        })
        .catch((err) => {
          console.log(err.response.data);
          setErrorMessage(err.response.data);
        });
    }
  };

  const delete_category = async (_id) => {
    var _confirm = confirm("Are you sure you want to delete or cancel to go back")
    if(_confirm){
    let axiosConfig = {
      Authorization: adminUserData?.token,
    };
    await axios
      .delete(`${BASE_URL}/category/${_id}`, axiosConfig)

      .then((res) => {
        fetchUsers();
        setSnackOpen(true);
        setMessage('Category deleted successfully');
        setMessageType('success');
      })
      .catch((err) => {
        console.log(err.data);
      });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackOpen(false);
  };

  const saveCategory = async () => {
    if (cat_title === '') {
      setErrorMessage('Please enter the Category Name');
    } else if (cat_desc === '') {
      setErrorMessage('Please enter the Category Description');
    } else if (tags.length === 0) {
      setErrorMessage('Please Select the Category Tags');
    } else if (category_img === '') {
      setErrorMessage('Please Select the Category Image');
    } else {
      let axiosConfig = {
        Authorization: adminUserData?.token,
      };
      await axios
        .post(
          `${BASE_URL}/category`,
          {
            name: cat_title,
            description: cat_desc,
            image: category_img,
            tags: tags,
          },
          axiosConfig
        )
        .then((res) => {
          fetchUsers();
          setviewDetails(false);
          setcat_desc('');
          setcat_title('');
          settags([]);
          setErrorMessage('');
          setSnackOpen(true);
          setMessage('Category saved successfully');
          setMessageType('success');
        })
        .catch((err) => {
          console.log(err.data);
          setErrorMessage(err.data);
        });
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const categoryhandlechange = (v, type) => {
    var _data = selected;
    console.log(_data.id);
    if (type === 'name') {
      _data.name = v;
      setSelected({ ..._data });
    } else if (type === 'desc') {
      _data.description = v;
      setSelected({ ..._data });
    } else if (type === 'tags') {
      selected.tags = v;
      setSelected(_data);
    }
    // if()
    setErrorMessage('');
  };

  // UPLOAD_URL
  const upload_image = (_file, type) => {
    console.log('-------_file-------->', _file);
    var formData = new FormData();
    formData.append('file', _file);
    formData.append('upload_preset', 'ml_default');
    formData.append('api_key', '767293436452984');

    axios({
      method: 'post',
      url: UPLOAD_URL,
      data: formData,
    })
      .then(function (response) {
        if (type === 'edit') {
          selected.image = response.data.url;
          setSelected({ ...selected });
        } else {
          set_category_img(response.data.url);
          setErrorMessage('');
        }
        console.log('////response/////', response.data.url);
      })
      .catch(function (error) {
        console.log('////error/////', error);
      });
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - usersList.length) : 0;

  console.log(start_date);

  const filteredUsers = usersList.filter((users) => {
    return users.name?.toLowerCase().includes(searchText.toLowerCase());
  });
  const isUserNotFound = filteredUsers.length === 0;
  return (
    <Page title="Users">
      {!viewDetail ? (
        <Container>
          <Grid container justifyContent={'space-between'}>
            <Grid sm={5} xs={5} md={3} lg={3} xl={3}>
              <div className="_login_row" style={{ marginBottom: 4 }}>
                <SearchIcon className="_auth_fields_icons" />
                <input
                  type={'text'}
                  placeholder="Search"
                  className="_auth_fields"
                  value={searchText}
                  onChange={(e) => setsearchText(e.target.value)}
                />
              </div>
            </Grid>

            <Grid sm={12} xs={12} md={3} lg={2} xl={2}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginBottom: 5 }}>
                {adminUserData.role === 'admin' ? (
                  <button
                    className="_btn"
                    style={{ width: '100%', alignSelf: 'flex-end' }}
                    onClick={() => {
                      setviewDetails(true);
                    }}
                  >
                    <IoIosAdd style={{ color: 'white' }} size={25} /> Add Category
                  </button>
                ) : null}
              </div>
            </Grid>
          </Grid>
          <br />
          <Card style={{ borderRadius: 0 }}>
            <Scrollbar>
              <TableContainer>
                {loading ? (
                  <div className="_loading_section">
                    {' '}
                    <CircularProgress color="secondary" />
                  </div>
                ) : (
                  <Table>
                    <UserListHead headLabel={TABLE_HEAD} rowCount={usersList.length} />
                    <TableBody>
                      {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, key) => {
                        const { id, name, description, image } = row;
                        return (
                          <TableRow hover key={id} style={{ fontSize: 14, fontWeight: 400 }}>
                            <TableCell scope="row">
                              <Stack
                                direction="row"
                                alignItems="center"
                                spacing={2}
                                style={{ cursor: 'pointer' }}

                                // onClick={() => {
                                //   setviewWallet(true), setselectedUser(row);
                                // }}
                              >
                                {/* <BiCategory color={'#972b34'} size={20} /> */}
                                <img src={row.image} style={{ height: 20, width: 20 }} />

                                <div>{row.name ? row.name : '--'}</div>
                              </Stack>
                            </TableCell>

                            <TableCell scope="row">
                              <Stack direction="row" alignItems="center" spacing={2} style={{ cursor: 'pointer' }}>
                                <div>{row.description ? row.description : '--'}</div>
                              </Stack>
                            </TableCell>
                            <TableCell align="left">
                              {row.tags.map((val, i) => {
                                return (
                                  <div key={i} className="_tags">
                                    {val}
                                  </div>
                                );
                              })}
                            </TableCell>

                            <TableCell align="right">
                              {adminUserData.role === 'admin' ? (
                                <div className="_action_row">
                                  <div
                                    onClick={() => {
                                      setSelected(row), setOpen(true);
                                    }}
                                  >
                                    <EditIcon color="#972b34" />
                                  </div>
                                  <div onClick={() => delete_category(row.id)}>
                                    <DeleteIcon color="#972b34" />
                                  </div>
                                </div>
                              ) : null}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                    {isUserNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filterName} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                )}
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={usersList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
          {/* CHANGE STATUS MODAL */}

          <BootstrapDialog aria-labelledby="customized-dialog-title" open={open} maxWidth="sm" fullWidth={true}>
            <div className="_user_modal_header">
              <div>Edit Category</div>
              <span onClick={() => setOpen(false)}>
                <CloseIcon />
              </span>
            </div>

            <Grid container direction="row" justifyContent="center" alignItems="center" style={{ padding: 20 }}>
              <Grid sm={12}>
                <Grid container>
                  <Grid sm={12} xs={12} md={12} lg={12} xl={12}>
                    <div className="_verification_row">
                      <label>Category Name</label>
                      <input
                        className="_verification_fields"
                        value={selected?.name}
                        onChange={(e) => categoryhandlechange(e.target.value, 'name')}
                      />
                    </div>
                  </Grid>
                  <Grid sm={12} xs={12} md={12} lg={12} xl={12}>
                    <div className="_verification_row">
                      <label>Category Description</label>
                      <input
                        className="_verification_fields"
                        value={selected?.description}
                        onChange={(e) => categoryhandlechange(e.target.value, 'desc')}
                      />
                    </div>
                  </Grid>
                  <Grid sm={12} xs={12} md={12} lg={12} xl={12}>
                    <div className="_verification_row">
                      <label>Category Tags</label>

                      <TagsInput
                        className="rti--container"
                        value={selected?.tags}
                        // onChange={settags}
                        onChange={(e) => categoryhandlechange(e, 'tags')}
                        name="tags"
                        placeHolder="Enter tags"
                      />
                    </div>
                  </Grid>
                  <Grid sm={12} xs={12} md={12} lg={12} xl={12}>
                    <div className="_verification_row">
                      <label>Image</label>
                      <label htmlFor="_update_promo">
                        <img src={selected?.image} style={{ height: 150, width: '100%' }} />
                      </label>
                      <input
                        className="_verification_fields"
                        // value={cat_desc}
                        type="file"
                        id="_update_promo"
                        style={{ height: 50, display: 'none' }}
                        onChange={(e) => upload_image(e.target.files[0], 'edit')}
                        // onChange={(event, value) => categoryhandlechange(e.target.value, 'image')}
                      />

                      {/*  */}
                    </div>
                  </Grid>
                  <Grid sm={12} xs={12} md={12} lg={12} xl={12}>
                    <div className="_verification_row">
                      <p style={{ color: 'red', fontSize: 12 }}>{errorMessage}</p>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <DialogActions>
              <div className="_user_modal_footer">
                <button className="_btn outline_btn_modal" onClick={() => setOpen(false)}>
                  Cancel
                </button>
                <button className="_btn" onClick={() => updateCategory()}>
                  Update
                </button>
              </div>
            </DialogActions>
          </BootstrapDialog>
        </Container>
      ) : (
        <Container>
          <div className="_user_details_title">
            {' '}
            <span>Category</span>/Add Category
          </div>
          <Grid container direction="row" justifyContent="center" alignItems="center">
            <Grid sm={12} xs={12} md={6} lg={6} xl={6}>
              <Grid container>
                <Grid sm={12} xs={12} md={12} lg={12} xl={12}>
                  <div className="_verification_row">
                    <label>Category Name</label>
                    <input
                      className="_verification_fields"
                      value={cat_title}
                      onChange={(e) => {
                        setcat_title(e.target.value);
                        setErrorMessage('');
                      }}
                    />
                  </div>
                </Grid>
                <Grid sm={12} xs={12} md={12} lg={12} xl={12}>
                  <div className="_verification_row">
                    <label>Category Description</label>
                    <input
                      className="_verification_fields"
                      value={cat_desc}
                      onChange={(e) => {
                        setcat_desc(e.target.value);
                        setErrorMessage('');
                      }}
                    />
                  </div>
                </Grid>
                <Grid sm={12} xs={12} md={12} lg={12} xl={12}>
                  <div className="_verification_row">
                    <label>Category Tags</label>

                    <TagsInput
                      className="rti--container"
                      value={tags}
                      onChange={settags}
                      name="tags"
                      placeHolder="Enter tags"
                    />
                  </div>
                </Grid>
                <Grid sm={12} xs={12} md={12} lg={12} xl={12}>
                  {/* <div className="_verification_row">
                    <label>Image</label>
                    <input
                      className="_verification_fields"
                      // value={category_img}
                      type="file"
                      style={{ height: 50 }}

                      onChange={(e) => upload_image(e.target.files[0], 'save')}
                    />
                  </div> */}
                  <div className="_verification_row">
                    <label>Image</label>
                    <label htmlFor="_update_promo">
                      {category_img === '' ? (
                        <div>
                          <label
                            htmlFor="_update_promo"
                            className="_verification_fields"
                            style={{ border: '1px solid', display: 'block', textAlign: 'center', marginTop: 10 }}
                          >
                            <AiOutlineFileAdd size={20} color="#972B34" style={{ marginBottom: -5 }} />
                            upload image
                          </label>
                        </div>
                      ) : (
                        <img src={category_img} style={{ height: 150, width: '100%', border: '1px solid' }} />
                      )}
                    </label>
                    <input
                      className="_verification_fields"
                      // value={category_img}
                      type="file"
                      id="_update_promo"
                      style={{ height: 50, display: 'none' }}
                      onChange={(e) => upload_image(e.target.files[0], 'save')}
                    />

                    {/*  */}
                  </div>
                </Grid>
                <Grid sm={12} xs={12} md={12} lg={12} xl={12}>
                  <div className="_verification_row">
                    <p style={{ color: 'red', fontSize: 12 }}>{errorMessage}</p>
                  </div>
                </Grid>

                <Grid sm={12} xs={12} md={12} lg={12} xl={12}>
                  <div className="_verification_row">
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        cursor: 'pointer',
                        // border: '2px solid black',
                      }}
                      onClick={() => saveCategory()}
                    >
                      <button
                        style={{ width: 163 }}
                        className="_btn outline_btn_modal"
                        onClick={() => setviewDetails(false)}
                      >
                        Cancel
                      </button>
                      <button className="_btn" style={{ width: 163 }}>
                        Save Category
                      </button>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>

            {/* <Grid sm={12} xs={12} md={6} lg={6} xl={6}>
              <div className="_document_view">
                {loadkyc ? (
                  <div className="_loading_section">
                    {' '}
                    <CircularProgress color="secondary" />
                  </div>
                ) : (
                  <>
                    <label>Attached Documents</label>
                    <div className="_documents_row">
                      {kyc.length !== 0 ? (
                        <img src={kyc[0].document.path} style={{ border: '1px solid', height: 200, width: '100%' }} />
                      ) : (
                        <p style={{ textAlign: 'center' }}>Not Found</p>
                      )}
                    </div>
                    <br />

                   
                  </>
                )}
              </div>
              <br />

              {kyc.length !== 0 && (
                <div
                  style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}
                >
                  <button
                    className="_btn"
                    style={{ width: 85, margin: 5, backgroundColor: '#F43131', border: 'none' }}
                    onClick={() => rejectDoc()}
                  >
                    {selected.data?.verificationStatus === 'REJECTED' ? 'Rejected' : 'Reject'}
                  </button>
                  {selected.data?.verificationStatus === 'VERIFIED' && (
                    <button
                      className="_btn"
                      style={{ width: 85, marign: 5, backgroundColor: '#4FCC3B', border: 'none' }}
                      disabled
                    >
                      Accepted
                    </button>
                  )}

                  {selected.data?.verificationStatus === 'PENDING' && (
                    <button
                      className="_btn"
                      style={{ width: 85, marign: 5, backgroundColor: '#4FCC3B', border: 'none' }}
                      onClick={() => acceptDoc()}
                    >
                      Accept
                    </button>
                  )}
                </div>
              )}
            </Grid> */}
          </Grid>
        </Container>
      )}
      <Snackbar open={snackOpen} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={messageType} sx={{ width: '100%' }} style={{ color: '#fff' }}>
          {message}
        </Alert>
      </Snackbar>
    </Page>
  );
}
